
      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "UNION",
        "name": "Viewable",
        "possibleTypes": [
          {
            "name": "Asset"
          },
          {
            "name": "Series"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "ViewableTwo",
        "possibleTypes": [
          {
            "name": "Series"
          },
          {
            "name": "Episode"
          },
          {
            "name": "SportEvent"
          },
          {
            "name": "Clip"
          },
          {
            "name": "Movie"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "FavoriteTwo",
        "possibleTypes": [
          {
            "name": "Series"
          },
          {
            "name": "Movie"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "SeriesSeason",
        "possibleTypes": [
          {
            "name": "BrandSeason"
          },
          {
            "name": "ProgramFamilySeason"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "Playable",
        "possibleTypes": [
          {
            "name": "Episode"
          },
          {
            "name": "SportEvent"
          },
          {
            "name": "Clip"
          },
          {
            "name": "Movie"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "Resumable",
        "possibleTypes": [
          {
            "name": "Episode"
          },
          {
            "name": "Movie"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "TimeSlot",
        "possibleTypes": [
          {
            "name": "Program"
          },
          {
            "name": "GapFiller"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "Panel",
        "possibleTypes": [
          {
            "name": "LiveSportsList"
          },
          {
            "name": "EditorialItem"
          },
          {
            "name": "EditorialAsset"
          },
          {
            "name": "EditorialList"
          },
          {
            "name": "EditorialBanner"
          },
          {
            "name": "AssetList"
          },
          {
            "name": "MyList"
          },
          {
            "name": "ShowCase"
          },
          {
            "name": "ContinueWatchingList"
          },
          {
            "name": "SportChannelList"
          },
          {
            "name": "SimilarsList"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "Favorite",
        "possibleTypes": [
          {
            "name": "Asset"
          },
          {
            "name": "Series"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "SportSectionItem",
        "possibleTypes": [
          {
            "name": "SportEvent"
          },
          {
            "name": "TimeDivider"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "SectionItem",
        "possibleTypes": [
          {
            "name": "Asset"
          },
          {
            "name": "TimeDivider"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "UserPaymentMethod",
        "possibleTypes": [
          {
            "name": "KlarnaPaymentMethod"
          },
          {
            "name": "CreditCardPaymentMethod"
          },
          {
            "name": "ApplePaymentMethod"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "PaymentOptions",
        "possibleTypes": [
          {
            "name": "Dibs"
          },
          {
            "name": "Klarna"
          }
        ]
      }
    ]
  }
};
      export default result;
    