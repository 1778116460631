import { withFocusable } from "@noriginmedia/react-spatial-navigation";
import React, { Fragment, useCallback, useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  EditorialListFragment,
  LiveSportsListFragment,
  PageAssetListFragment,
  PageAssetListItemFragment,
  usePageQuery
} from "./graphql";
import Header from "./Header";
import Menu from "./Menu";
import { MenuContext } from "./MenuContext";
import { PageFocusItemContext } from "./PageFocusContext";
import {
  LandscapeListModule,
  PanelsContainer,
  PosterListModule
} from "./PosterListModule";

const Page = ({ setFocus }) => {
  const focusItem = useContext(PageFocusItemContext);
  const firstOrReturningLoad = React.useRef(true);
  const { id } = useParams();
  const selectedMenuItem = useContext(MenuContext);
  const history = useHistory();
  const { data, loading } = usePageQuery({
    variables: { id: id || "start", limit: 15 }
  });

  useEffect(() => {
    if (focusItem) {
      setFocus(`${focusItem.row}-${focusItem.column}`);
    }
  }, [focusItem, setFocus]);

  useEffect(() => {
    if (!loading && !firstOrReturningLoad.current) {
      setFocus("0-0");
    }
    firstOrReturningLoad.current = false;
  }, [loading, selectedMenuItem, setFocus]);

  const onEnterPress = useCallback(
    ({ itemId }) => {
      history.push(`/viewable/${itemId}`);
    },
    [history]
  );

  if (!data || !data.page || !data.page.panels) return null;

  const panels = (data?.page?.panels || []).filter(
    p =>
      p &&
      (p.__typename === "AssetList" ||
        p.__typename === "LiveSportsList" ||
        p.__typename === "EditorialList")
  ) as (
    | PageAssetListFragment
    | LiveSportsListFragment
    | EditorialListFragment
  )[];

  const headerItem: PageAssetListItemFragment | LiveSportsListFragment =
    focusItem && panels.length
      ? panels[focusItem.row]?.items[focusItem.column]
      : undefined;

  return (
    <Fragment>
      {headerItem && (
        <Header
          image={headerItem.image16x9}
          description={headerItem.description}
          year={headerItem.year}
          genre={headerItem.genre}
          title={
            headerItem.__typename === "Episode"
              ? headerItem.series.title
              : headerItem.title
          }
        />
      )}
      <Menu />
      <PanelsContainer>
        {panels.map((p, index) => {
          if (p && p.__typename === "AssetList") {
            const assetList = p as PageAssetListFragment;

            return (
              <PosterListModule
                selectedColumn={
                  focusItem?.row === index ? focusItem.column : -1
                }
                rowNumber={index}
                key={assetList.id}
                list={assetList}
                onEnterPress={onEnterPress}
              />
            );
          }

          if (p && p.__typename === "EditorialList") {
            const assetList = p as EditorialListFragment;

            return (
              <LandscapeListModule
                selectedColumn={
                  focusItem?.row === index ? focusItem.column : -1
                }
                rowNumber={index}
                key={assetList.id}
                list={assetList}
                onEnterPress={onEnterPress}
              />
            );
          }

          if (p && p.__typename === "LiveSportsList") {
            const assetList = p as LiveSportsListFragment;

            return (
              <LandscapeListModule
                selectedColumn={
                  focusItem?.row === index ? focusItem.column : -1
                }
                rowNumber={index}
                key={assetList.id}
                list={assetList}
                onEnterPress={onEnterPress}
              />
            );
          }

          return null;
        })}
      </PanelsContainer>
    </Fragment>
  );
};

const FocusablePage = withFocusable()(Page);

export default FocusablePage;
