import React from "react";
import { useUserQuery } from "./graphql";

export const UserContext = React.createContext(null);

export const UserContextProvider = ({ children }) => {
  const { data, error, loading } = useUserQuery();

  if (loading) {
    return null;
  }

  if (error && !data) {
    localStorage.clear();
  }

  return (
    <UserContext.Provider value={[data?.user]}>{children}</UserContext.Provider>
  );
};
