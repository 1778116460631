import React, { useState } from "react";
import { useDebounce } from './hooks'

const BackgroundImageContext = React.createContext<string>(null);

const BackgroundImagSetterContext = React.createContext<(url: string) => void>(
  null
);

const BackgroundImageContextProvider = ({ children }) => {
  const [backgroundImage, setbackgroundImage] = useState<string | undefined>(
    undefined
  );
  const image = useDebounce(backgroundImage, 1500)

  return (
    <BackgroundImagSetterContext.Provider value={setbackgroundImage}>
      <BackgroundImageContext.Provider value={image}>
        {children}
      </BackgroundImageContext.Provider>
    </BackgroundImagSetterContext.Provider>
  );
};

export {
  BackgroundImageContext,
  BackgroundImagSetterContext,
  BackgroundImageContextProvider
};
