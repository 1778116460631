import { withFocusable } from "@noriginmedia/react-spatial-navigation";
import React, { Fragment, ReactNode, useCallback, useContext } from "react";
import styled from "styled-components";
import { imageProxyUrl } from "./imageProxy";
import {
  PageFocusItemContext,
  PageFocusItemSetterContext
} from "./PageFocusContext";
import { H3 } from "./typography";

const POSTER_ITEM_WIDTH = 227; // Compute from element instead.
const LANDSCAPE_ITEM_WIDTH = 580;

const FocusedPoster = styled.div`
  transition: all 0.2s ease-in-out;
  transform: ${props => (props.focused ? "scale(1.14)" : "none")};
  margin: 8px 14px;
  min-width: 200px;
`;

const FocusedLandscape = styled.div`
  transition: all 0.2s ease-in-out;
  transform: ${props => (props.focused ? "scale(1.14)" : "none")};
  margin: 8px 24px;
  min-width: 533px;
`;

const Title = styled(H3)`
  margin: 16px 0 32px 72px;
`;

const ScrollableList = styled.div`
  transition: transform 0.2s ease;
  display: flex;
  flex-direction: row;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  > div:first-child {
    margin-left: 72px;
  }
`;

const AssetListModule = ({
  rowNumber,
  list,
  selectedColumn,
  itemWidth,
  render,
  preferredChildFocusKey
}: {
  rowNumber: number;
  list: PosterListData;
  selectedColumn?: number;
  itemWidth: number;
  render: ItemRender;
  preferredChildFocusKey: string;
}) => {
  const setFocusItem = useContext(PageFocusItemSetterContext);

  const onBecameFocused = useCallback(
    (_, { row, column }) => {
      setFocusItem({ row, column });
    },
    [setFocusItem]
  );

  if (!list?.items?.length) {
    return null;
  }

  console.log("render!", list.title);

  const leftScroll = selectedColumn ? selectedColumn * itemWidth : 0;

  return (
    <Fragment>
      <Title>{list.title}</Title>
      <ScrollableList style={{ transform: `translateX(-${leftScroll}px)` }}>
        {render(list.items, rowNumber, onBecameFocused)}
      </ScrollableList>
    </Fragment>
  );
};

const FocusableAssetListModule = withFocusable()(AssetListModule);

const PosterItemComponent = React.memo(
  withFocusable()(
    ({
      image2x3,
      title,
      focused = false
    }: {
      image2x3: string;
      title: string;
      focused?: boolean;
    }) => {
      return (
        <FocusedPoster focused={focused}>
          <img
            alt={title}
            src={imageProxyUrl(image2x3, 200, 300)}
            style={{ height: "300px", width: "200px" }}
          />
        </FocusedPoster>
      );
    }
  )
);

type PosterListData = {
  title?: string;
  items?: {
    id: string;
    title?: string;
    image2x3?: string;
  }[];
};

const LandscapeItemComponent = React.memo(
  withFocusable()(
    ({
      image16x9,
      title,
      focused = false
    }: {
      image16x9: string;
      title: string;
      focused?: boolean;
    }) => {
      return (
        <FocusedLandscape focused={focused}>
          <img
            alt={title}
            src={imageProxyUrl(image16x9, 533, 300)}
            style={{ height: "300px", width: "533px" }}
          />
        </FocusedLandscape>
      );
    }
  )
);

type LandscapeListData = {
  title?: string;
  items?: {
    id: string;
    title?: string;
    image16x9?: string;
  }[];
};

interface ItemRender {
  (items: any[], rowNumber: number, onBecameFocused: Function): ReactNode;
}

export const PosterListModule = React.memo(
  ({
    rowNumber,
    list,
    selectedColumn,
    onEnterPress
  }: {
    rowNumber: number;
    list: PosterListData;
    selectedColumn: number;
    onEnterPress: Function;
  }) => {
    const renderItems = (items, rowNumber, onBecameFocused) => {
      return items.map((item, index) => {
        if (!item || !item.image2x3) return null;
        return (
          <PosterItemComponent
            key={index}
            focusKey={`${rowNumber}-${index}`}
            title={item.title}
            image2x3={item.image2x3}
            row={rowNumber}
            column={index}
            itemId={item.id}
            onBecameFocused={onBecameFocused}
            onEnterPress={onEnterPress}
          />
        );
      });
    };

    return (
      <FocusableAssetListModule
        rowNumber={rowNumber}
        list={list}
        selectedColumn={selectedColumn}
        itemWidth={POSTER_ITEM_WIDTH}
        render={renderItems}
        preferredChildFocusKey={`${rowNumber}-${selectedColumn}`}
      />
    );
  }
);

export const LandscapeListModule = React.memo(
  ({
    rowNumber,
    list,
    selectedColumn,
    onEnterPress
  }: {
    rowNumber: number;
    list: LandscapeListData;
    selectedColumn: number;
    onEnterPress: Function;
  }) => {
    const renderItems = (items, rowNumber, onBecameFocused) => {
      return items.map((item, index) => {
        if (!item || !item.image16x9) return null;
        return (
          <LandscapeItemComponent
            key={index}
            focusKey={`${rowNumber}-${index}`}
            title={item.title}
            image16x9={item.image16x9}
            row={rowNumber}
            column={index}
            itemId={item.id}
            onBecameFocused={onBecameFocused}
            onEnterPress={onEnterPress}
          />
        );
      });
    };

    return (
      <FocusableAssetListModule
        rowNumber={rowNumber}
        list={list}
        selectedColumn={selectedColumn}
        itemWidth={LANDSCAPE_ITEM_WIDTH}
        preferredChildFocusKey={`${rowNumber}-${selectedColumn}`}
        render={renderItems}
      />
    );
  }
);

const ITEM_HEIGHT = 410; // Compute from element instead.

const PanelsContainerStyles = styled.div`
  overflow: hidden;
  > div {
    transition: transform 0.2s ease;
  }
  height: 50%;
  max-width: 100%;
  position: absolute;
  margin: 0 80px;
  top: 50%;
  ::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
`;

export const PanelsContainer = ({ children }) => {
  const focusItem = useContext(PageFocusItemContext);

  return (
    <PanelsContainerStyles>
      <div
        style={{
          transform: `translateY(-${
            focusItem ? focusItem.row * ITEM_HEIGHT : 0
          }px)`
        }}
      >
        {children}
      </div>
    </PanelsContainerStyles>
  );
};
