export type State = { [key: string]: string; }

export const PlayerState: State = {
  LOADING: 'loading',
  PLAYING: 'playing',
  PAUSED: 'paused',
  FORWARDING: 'forwarding',
  REWINDING: 'rewinding',
  ENDED: 'ended',
}
