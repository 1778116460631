import { ApolloProvider } from "@apollo/react-hooks";
import {
  initNavigation,
  setKeyMap
} from "@noriginmedia/react-spatial-navigation";
import ApolloClient from "apollo-boost";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from "apollo-cache-inmemory";
import { createBrowserHistory } from "history";
import React, { Fragment, useContext } from "react";
import { Route, Router, Switch } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";
import styled, { createGlobalStyle } from "styled-components";
import {
  BackgroundImageContext,
  BackgroundImageContextProvider
} from "./BackgroundImageContext";
import { imageProxyUrl } from "./imageProxy";
import introspectionResult from "./introspection-result";
import Login from "./Login";
import { MenuContextProvider } from "./MenuContext";
import Page from "./Page";
import { PageFocusContextProvider } from "./PageFocusContext";
import Play from "./Play";
import Profile from "./Profile";
import { UserContext, UserContextProvider } from "./UserContext";
import Viewable from "./Viewable";
import Channels from "./Channels";

const history = createBrowserHistory();

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: introspectionResult
});

const client = new ApolloClient({
  cache: new InMemoryCache({ fragmentMatcher }),
  uri: "https://beta-graphql.cmore.se/graphql",
  request: operation => {
    const token = localStorage.getItem("token");
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : ""
      }
    });
  }
});

initNavigation({
  debug: false,
  visualDebug: false
});

// Optional
setKeyMap({
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  enter: 13
});

smoothscroll.polyfill();

const GlobalStyles = createGlobalStyle`
  body {
    height: 100%;
    background-color: #06111F;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    font-family: Roboto, sans-serif;
    ::-webkit-scrollbar {
      width: 0px;  /* Remove scrollbar space */
      background: transparent;  /* Optional: just make scrollbar invisible */
    }
  }
`;

const AppWrapper = () => {
  return (
    <ApolloProvider client={client}>
      <UserContextProvider>
        <MenuContextProvider>
          <GlobalStyles />
          <App></App>
        </MenuContextProvider>
      </UserContextProvider>
    </ApolloProvider>
  );
};

const Background = ({ children }) => {
  const backgroundImage = useContext(BackgroundImageContext);

  const BackgroundImage = styled.div`
    height: 100vh;
    width: 100vw;
    background-image: linear-gradient(
        to left bottom,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 1)
      ),
      url(${props => props.src});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top right;
    position: absolute;
    z-index: -1;
  `;

  return (
    <>
      {backgroundImage && (
        <BackgroundImage src={imageProxyUrl(backgroundImage, 950, 640)} />
      )}
      {children}
    </>
  );
};

const App = () => {
  const [user] = useContext(UserContext);
  return (
    <PageFocusContextProvider>
      <Router history={history}>
        {!user ? (
          <Login />
        ) : (
          <Fragment>
            <BackgroundImageContextProvider>
              <Background>
                <Switch>
                  <Route exact path="/">
                    <Page />
                  </Route>
                  <Route path="/pages/:id">
                    <Page />
                  </Route>
                  <Route path="/channels">
                    <Channels />
                  </Route>
                  <Route path="/viewable/:id">
                    <Viewable />
                  </Route>
                  <Route path="/play/:id">
                    <Play />
                  </Route>
                  <Route path="/profile">
                    <Profile />
                  </Route>
                </Switch>
              </Background>
            </BackgroundImageContextProvider>
          </Fragment>
        )}
      </Router>
    </PageFocusContextProvider>
  );
};

export default AppWrapper;
