import React, { useEffect, useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { H1, P } from "./typography";
import { ReactComponent as CmoreLogo } from './icons/logo.svg';

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  margin-top: 232px;
  margin-left: 440px;
`

const Item = styled.div`
  display: flex;
  align-items: center
  margin-top: 53px;
`

const PLarge = styled(P)`
  font-size: 32px;
`

const Text = styled(PLarge)`
  display: block;
  margin-left: 36px;
`;

const Code = styled(H1)`
  margin-left: 36px;
  letter-spacing: 8px;
`;

const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  height: 294px;
  border-top: 2px solid #3C3C3C;
  width: 100%;
  bottom: 0;
`

const Footer = styled.div`
  margin-left: 440px;
  display: flex;
`

const Logo = styled(CmoreLogo)`
  margin-left: 220px;
`

const activationUrl =
  "https://activation-bravo.b17g.services/api?client=cmore-lg";

const useGetCode = () => {
  const [code, setCode] = useState(null);
  const [deviceId, setDeviceId] = useState(null);

  useEffect(() => {
    (async () => {
      const id = parseInt(String(Math.random() * 1000));
      setDeviceId(id);

      const query = `mutation {
        create (deviceId: "${id}") {
          code
        }
      }`;

      const response = await fetch(activationUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ query })
      });

      if (response.ok) {
        const json = await response.json();
        setCode(json.data?.create?.code);
      }
    })();
  }, []);
  return { code: code, deviceId };
};

const activeDevice = async (deviceId, activationCode) => {
  const query = `query {
      activation(activationCode: "${activationCode}", deviceId: "${deviceId}") {
        token
      }
    }`;

  const response = await fetch(activationUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ query })
  });

  if (response.ok) {
    const json = await response.json();
    return json?.data?.activation?.token;
  }
  return null;
};

const Login = () => {
  const { code, deviceId } = useGetCode();
  const history = useHistory();

  useEffect(() => {
    const doIt = async () => {
      const token = await activeDevice(deviceId, code);
      if (token) {
        localStorage.setItem("token", token);
        window.location.reload();
      }
      return token;
    };

    let timeout = null;

    if (code && deviceId) {
      const func = async () => {
        const result = await doIt();
        if (!result) {
          timeout = setTimeout(func, 3000);
        }
      };
      func();
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [code, deviceId, history]);

  return (
    <Fragment>
      <Wrapper>
        <H1>Koppla TV:n till ditt konto</H1>
        <Item>
          <H1>1</H1>
          <Text>Gå till www.activate.cmore.se <br />på din dator, mobil eller surfplatta</Text>
        </Item>
        <Item>
          <H1>2</H1>
          <Text>Skriv in koden: </Text><Code>{code}</Code>
        </Item>
        <Item>
          <H1>3</H1>
          <Text>Börja titta</Text>
        </Item>
      </Wrapper>
      <FooterWrapper>
        <Footer>
          <div>
          <PLarge>Har du inget paket på C More?</PLarge>
          <PLarge>Skaffa ett på www.cmore.se</PLarge>
          </div>
          <Logo />
        </Footer>
      </FooterWrapper>
    </Fragment>
  );
};

export default Login;
