const getclient = () => {
  const ua = window.navigator.userAgent;
  if (ua === "Mozilla/5.0 (Web0S; Linux/SmartTV) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/53.0.2785.34 Safari/537.36 WebAppManager") {
    return 'webOS-4.x'
  }
  if (ua === "Mozilla/5.0 (Web0S; Linux/SmartTV) AppleWebKit/537.36 (KHTML, like Gecko) QtWebEngine/5.2.1 Chrome/38.0.2125.122 Safari/537.36 WebAppManager") {
    return 'webOS-3.x'
  }
  if (ua === "Mozilla/5.0 (SMART-TV; LINUX; Tizen 5.0) AppleWebKit/537.36 (KHTML, like Gecko) Version/5.0 TV Chrome/63.0.3239.84 Safari/537.36") {
    return "Samsung-2019"
  }
  if (ua === "Mozilla/5.0 (SMART-TV; LINUX; Tizen 4.0) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 TV Safari/537.36") {
    return "Samsung-2018"
  }
  if (ua === "Mozilla/5.0 (SMART-TV; LINUX; Tizen 3.0) AppleWebKit/538.1 (KHTML, like Gecko) Version/3.0 TV Safari/538.1") {
    return "Samsung-2017"
  }
  return "";
}

export default ({ youboraConfig, user }) => ({
  userType: "OTT",
  username: user.id,
  "extraparam.1": `C More Smart-TV ${getclient()}`,
  content: {
    id: youboraConfig.id,
    title: youboraConfig.title,
    isLive: youboraConfig.isLive,
    metadata: youboraConfig,
  },
});
