import { withFocusable } from "@noriginmedia/react-spatial-navigation";
import React, { useCallback, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useChannelsQuery } from "./graphql";
import Header from "./Header";
import Menu from "./Menu";
import { PageFocusItemContext } from "./PageFocusContext";
import { LandscapeListModule, PanelsContainer } from "./PosterListModule";

const Channels = withFocusable()(({ setFocus }) => {
  const { data, loading } = useChannelsQuery();
  const history = useHistory();
  const focusItem = useContext(PageFocusItemContext);

  useEffect(() => {
    if (focusItem) {
      setFocus(`${focusItem.row}-${focusItem.column}`);
      return;
    }
  }, [focusItem, setFocus]);

  const firstOrReturningLoad = React.useRef(true);

  const onChannelSelect = useCallback(
    ({ itemId }) => {
      history.push(`/play/${itemId}`);
    },
    [history]
  );

  const listDatas = React.useMemo(() => {
    if (!data?.channels?.length) {
      return [];
    }

    return data.channels.map(channel => {
      const s = channel.nowPlaying;
      return {
        title: channel.title,
        items: [
          {
            ...s,
            id: channel.assetId,
            image16x9: s.image || channel.logo,
            desc: channel.nowPlaying,
            description: s.__typename === "Program" ? s.description : ""
          }
        ]
      };
    });
  }, [data]);

  useEffect(() => {
    if (!loading && !firstOrReturningLoad.current && listDatas) {
      const col = listDatas[0].items.findIndex(s => s.nowPlaying);
      setFocus(`0-${col}`);
    }
    firstOrReturningLoad.current = false;
  }, [loading, setFocus, listDatas]);

  if (!listDatas.length) {
    return null;
  }

  const headerItem =
    focusItem && listDatas.length
      ? listDatas[focusItem.row]?.items[focusItem.column]
      : undefined;

  return (
    <>
      {headerItem && (
        <Header
          image={headerItem.image16x9}
          description={headerItem.description}
          title={headerItem.title}
        />
      )}
      <Menu />
      <PanelsContainer>
        {listDatas.map((list, index) => {
          return (
            <LandscapeListModule
              key={index}
              rowNumber={index}
              list={list}
              selectedColumn={focusItem?.row === index ? focusItem.column : -1}
              onEnterPress={onChannelSelect}
            />
          );
        })}
      </PanelsContainer>
    </>
  );
});

export default Channels;
