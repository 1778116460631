import { withFocusable } from "@noriginmedia/react-spatial-navigation";
import React, { useCallback, useContext, useEffect } from "react";
import styled from "styled-components";
import { useAssumeProfileMutation, User } from "./graphql";
import { H1, H3 } from './typography';
import { UserContext } from "./UserContext";

const Wrapper = styled.div`
  align-items: center;
  background-color: black;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  width: 100vw;
`;

const Items = styled.div`
  display: flex;
  margin-top: 48px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 24px;
`;

const Image = styled.img`
  width: 250px;
  border-radius: 8px;
  border:  ${props => props.focused ? "2px solid white" : "2px solid transparent"}
`;
const ProfileItem = withFocusable()(
  ({ focused, alias, img }) => (
    <Item>
      <Image focused={focused} src={img} />
      <H3>{alias}</H3>
    </Item>
  )
);

const Profile = ({ setFocus }) => {
  const user = useContext<User>(UserContext);
  const [assumeProfile] = useAssumeProfileMutation();

  useEffect(() => {
    setFocus(`profile-${user[0].currentProfile.id}`);
  }, [setFocus, user]);

  const setProfile = useCallback(
    async (id) => {
      const { data } = await assumeProfile({ variables: { profileId: id } });
      localStorage.setItem("token", data.assumeProfile.replace("Bearer ", ""));
      window.location.href = "/";
    },
    [assumeProfile]
  );

  return (
    <Wrapper>
      <H1>Byt profil</H1>
      <Items>
        {(user[0]?.profiles ?? []).map(({ id, alias, avatar: { head: img } }) => (
          <ProfileItem focusKey={`profile-${id}`} onEnterPress={() => setProfile(id)} key={id} alias={alias} img={img} />
        ))}
      </Items>
    </Wrapper>
  );
};

const FocusableProfile = withFocusable()(Profile);

export default FocusableProfile;
