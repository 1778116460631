import React, { useState } from "react";

type PageFocusItem = {
  row: number;
  column: number;
};

const PageFocusItemContext = React.createContext<PageFocusItem>(null);

const PageFocusItemSetterContext = React.createContext<
  (item: PageFocusItem) => void
>(null);

const PageFocusContextProvider = ({ children }) => {
  const [focusItem, setFocusItem] = useState<PageFocusItem | undefined>(
    undefined
  );

  return (
    <PageFocusItemSetterContext.Provider value={setFocusItem}>
      <PageFocusItemContext.Provider value={focusItem}>
        {children}
      </PageFocusItemContext.Provider>
    </PageFocusItemSetterContext.Provider>
  );
};

export {
  PageFocusItemContext,
  PageFocusItemSetterContext,
  PageFocusContextProvider
};
