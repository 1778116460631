import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
};

/** AgeGroup is used to filter out possible disturbing content based on agegroup */
export enum AgeGroup {
  /** No filter */
  Adult = 'ADULT',
  /** Content for 0 -> 11 */
  Child = 'CHILD',
  /** Content for 0 -> 6 */
  Child0_6 = 'CHILD0_6'
}

export type ApplePaymentMethod = {
   __typename?: 'ApplePaymentMethod',
  appleSettingsUrl?: Maybe<Scalars['String']>,
};

export type Article = {
   __typename?: 'Article',
  html?: Maybe<Scalars['String']>,
};

export type Asset = {
   __typename?: 'Asset',
  actors?: Maybe<Array<Scalars['String']>>,
  airedTimeInfo?: Maybe<Scalars['String']>,
  arena?: Maybe<Scalars['String']>,
  assetTitle?: Maybe<Scalars['String']>,
  awayteam?: Maybe<Team>,
  broadcastingInfo?: Maybe<Scalars['String']>,
  category?: Maybe<Scalars['String']>,
  cdpUrl?: Maybe<Scalars['String']>,
  cinemascope?: Maybe<Scalars['String']>,
  commentators?: Maybe<Array<Scalars['String']>>,
  contentSource?: Maybe<Scalars['String']>,
  country?: Maybe<Array<Maybe<Scalars['String']>>>,
  daysLeftInService?: Maybe<Scalars['Int']>,
  description?: Maybe<Scalars['String']>,
  descriptionExtended?: Maybe<Scalars['String']>,
  directors?: Maybe<Array<Scalars['String']>>,
  displayCountry?: Maybe<Scalars['String']>,
  duration?: Maybe<Scalars['Int']>,
  endTime?: Maybe<Scalars['String']>,
  episode?: Maybe<Scalars['Int']>,
  episodeNumber?: Maybe<Scalars['Int']>,
  favorite?: Maybe<Scalars['Boolean']>,
  genre?: Maybe<Scalars['String']>,
  hometeam?: Maybe<Team>,
  id: Scalars['ID'],
  imdb?: Maybe<Imdb>,
  juneReference?: Maybe<Scalars['String']>,
  landscape?: Maybe<Scalars['String']>,
  league?: Maybe<Scalars['String']>,
  live?: Maybe<Scalars['Boolean']>,
  liveEventEnd?: Maybe<Scalars['String']>,
  liveNow?: Maybe<Scalars['Boolean']>,
  moreSportAssets?: Maybe<AssetCollection>,
  nextContent?: Maybe<NextContent>,
  parentalGuidance?: Maybe<Scalars['String']>,
  poster?: Maybe<Scalars['String']>,
  productGroups?: Maybe<Array<Maybe<Scalars['String']>>>,
  progress?: Maybe<Progress>,
  promotedProducts?: Maybe<PromotedProducts>,
  relativeUrl?: Maybe<Scalars['String']>,
  sameAssetInOtherLanguage?: Maybe<Array<Maybe<Asset>>>,
  season?: Maybe<Scalars['Int']>,
  seasonNumber?: Maybe<Scalars['Int']>,
  series?: Maybe<Series>,
  similars?: Maybe<Array<Maybe<Viewable>>>,
  startTime?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
  urlToCDP?: Maybe<Scalars['String']>,
  year?: Maybe<Scalars['Int']>,
  brandDescription?: Maybe<Scalars['String']>,
  brandDescriptionExtended?: Maybe<Scalars['String']>,
  brandLandscape?: Maybe<Scalars['String']>,
  brandTitle?: Maybe<Scalars['String']>,
  spokenLanguage?: Maybe<Array<Maybe<Scalars['String']>>>,
};


export type AssetActorsArgs = {
  limit?: Maybe<Scalars['Int']>
};


export type AssetMoreSportAssetsArgs = {
  page?: Maybe<Scalars['Int']>,
  pageSize?: Maybe<Scalars['Int']>
};


export type AssetPromotedProductsArgs = {
  ppvIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  subscriptionProductGroupIds?: Maybe<Array<Maybe<Scalars['ID']>>>
};

export type AssetCollection = {
   __typename?: 'AssetCollection',
  title?: Maybe<Scalars['String']>,
  viewables?: Maybe<Array<Maybe<Viewable>>>,
  assets?: Maybe<Array<Maybe<Asset>>>,
};

export type AssetList = {
   __typename?: 'AssetList',
  id: Scalars['ID'],
  title?: Maybe<Scalars['String']>,
  items?: Maybe<Array<Maybe<ViewableTwo>>>,
  viewables?: Maybe<Array<Maybe<Viewable>>>,
  assets?: Maybe<Array<Maybe<Asset>>>,
};


export type AssetListItemsArgs = {
  limit?: Maybe<Scalars['Int']>
};


export type AssetListViewablesArgs = {
  limit?: Maybe<Scalars['Int']>
};


export type AssetListAssetsArgs = {
  limit?: Maybe<Scalars['Int']>
};

export type AssetSearchResults = {
   __typename?: 'AssetSearchResults',
  totalHits?: Maybe<Scalars['Int']>,
  assets?: Maybe<Array<Maybe<Asset>>>,
};

export type Avatar = {
   __typename?: 'Avatar',
  id: Scalars['ID'],
  /** URL to image of the avatards head */
  head: Scalars['String'],
  /** URL to image of the fullbody avatar */
  body: Scalars['String'],
  url?: Maybe<Scalars['String']>,
};

/** AvatarFormat is no longer used. Profile service chooses image type. */
export enum AvatarFormat {
  Svg = 'SVG',
  Png = 'PNG'
}

export type BrandSeason = SeriesSeason & {
   __typename?: 'BrandSeason',
  brandId?: Maybe<Scalars['String']>,
  descriptionExtended?: Maybe<Scalars['String']>,
  episodes2?: Maybe<Array<Episode>>,
  numberOfEpisodes?: Maybe<Scalars['Int']>,
  seasonNumber?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
  episodes?: Maybe<Array<Asset>>,
};


export type BrandSeasonEpisodes2Args = {
  page?: Maybe<Scalars['Int']>,
  pageSize?: Maybe<Scalars['Int']>
};


export type BrandSeasonEpisodesArgs = {
  page?: Maybe<Scalars['Int']>,
  pageSize?: Maybe<Scalars['Int']>
};

export type Cancellation = {
   __typename?: 'Cancellation',
  terminateOrderDescription?: Maybe<Scalars['String']>,
  terminateOrderDoneAsset?: Maybe<Asset>,
  terminateOrderDoneDescription?: Maybe<Scalars['String']>,
  terminateOrderDoneHeader?: Maybe<Scalars['String']>,
  terminateOrderHeader?: Maybe<Scalars['String']>,
  terminateOrderReasonHeader?: Maybe<Scalars['String']>,
  terminateOrderReasons?: Maybe<Array<Maybe<Scalars['String']>>>,
  terminateOrderStepOneAsset?: Maybe<Asset>,
};

export type Channel = {
   __typename?: 'Channel',
  assetId?: Maybe<Scalars['ID']>,
  catchupAvailable: Scalars['Boolean'],
  darkLogo?: Maybe<Scalars['String']>,
  hasAccess?: Maybe<Scalars['Boolean']>,
  icon?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['ID']>,
  logo?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  nowPlaying?: Maybe<TimeSlot>,
  promotedSubscriptions?: Maybe<Array<Maybe<Product>>>,
  schedule?: Maybe<Array<Maybe<TimeSlot>>>,
  timeSlot?: Maybe<TimeSlot>,
  title?: Maybe<Scalars['String']>,
  asset?: Maybe<Asset>,
  channelId?: Maybe<Scalars['Int']>,
  currentSchedule?: Maybe<EpgSchedule>,
  scheduleDays?: Maybe<Array<Maybe<ScheduleDay>>>,
};


export type ChannelTimeSlotArgs = {
  id: Scalars['ID']
};


export type ChannelScheduleDaysArgs = {
  days?: Maybe<Scalars['Int']>
};

export enum ChannelIdType {
  EpgId = 'EpgId',
  AssetId = 'AssetId'
}

export enum ClientType {
  Android = 'android',
  Ios = 'ios',
  Mobile = 'mobile',
  Web = 'web'
}

export type Clip = Playable & ViewableTwo & {
   __typename?: 'Clip',
  id: Scalars['ID'],
  title?: Maybe<Scalars['String']>,
  image2x3?: Maybe<Scalars['String']>,
  image16x9?: Maybe<Scalars['String']>,
  relativeUrl?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  genre?: Maybe<Scalars['String']>,
  year?: Maybe<Scalars['Int']>,
  promotedSubscription?: Maybe<Product>,
  promotedSubscriptions?: Maybe<Array<Maybe<Product>>>,
  promotedPpv?: Maybe<Product>,
  readableDuration?: Maybe<Scalars['String']>,
  startTime?: Maybe<Scalars['String']>,
  similars?: Maybe<SimilarItems>,
  daysLeftInService?: Maybe<Scalars['Int']>,
  readableAvailability: Scalars['String'],
  descriptionExtended?: Maybe<Scalars['String']>,
  shouldReportProgress?: Maybe<Scalars['Boolean']>,
  /** Indicates if the playable can be downloaded. Dont overdo this query. Its heavy to fetch. */
  downloadable: Scalars['Boolean'],
  hasAccess: Scalars['Boolean'],
  parentalGuidance?: Maybe<Scalars['String']>,
};

export type ContentPanel = {
   __typename?: 'ContentPanel',
  assets?: Maybe<Array<Maybe<Asset>>>,
  id: Scalars['ID'],
  title?: Maybe<Scalars['String']>,
};

export type ContinueWatching = {
   __typename?: 'ContinueWatching',
  id: Scalars['ID'],
  items?: Maybe<Array<Maybe<ContinueWatchingItem>>>,
};

export type ContinueWatchingItem = {
   __typename?: 'ContinueWatchingItem',
  id: Scalars['ID'],
  label?: Maybe<Scalars['String']>,
  item?: Maybe<Resumable>,
  title?: Maybe<Scalars['String']>,
  subtitle?: Maybe<Scalars['String']>,
  new?: Maybe<Scalars['Boolean']>,
  asset?: Maybe<Asset>,
};

export type ContinueWatchingList = {
   __typename?: 'ContinueWatchingList',
  continueWatching?: Maybe<ContinueWatching>,
  id: Scalars['ID'],
  title?: Maybe<Scalars['String']>,
};

export type CreateProfilePayload = {
  alias: Scalars['String'],
  avatarId?: Maybe<Scalars['ID']>,
  themeId?: Maybe<Scalars['ID']>,
  ageGroup: AgeGroup,
};

export type CreateUserPayload = {
   __typename?: 'CreateUserPayload',
  userId?: Maybe<Scalars['String']>,
};

export type CreditCardPaymentMethod = {
   __typename?: 'CreditCardPaymentMethod',
  creditCardExpiryDate?: Maybe<Scalars['String']>,
  creditCardNumber?: Maybe<Scalars['String']>,
  nextChargeDate?: Maybe<Scalars['String']>,
  creditCardUpdateUrl?: Maybe<Scalars['String']>,
  orderId?: Maybe<Scalars['ID']>,
};


export type CreditCardPaymentMethodCreditCardUpdateUrlArgs = {
  successCallbackUrl: Scalars['String'],
  errorCallbackUrl: Scalars['String']
};

export type Dibs = {
   __typename?: 'Dibs',
  orderId?: Maybe<Scalars['ID']>,
  url?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  initPrice?: Maybe<Scalars['Float']>,
};

export type EditorialAsset = {
   __typename?: 'EditorialAsset',
  ctaText?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  imageUrl?: Maybe<Scalars['String']>,
  item?: Maybe<Playable>,
  showMoreUrl?: Maybe<Scalars['String']>,
  subTitle?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  asset?: Maybe<Asset>,
};

export type EditorialBanner = {
   __typename?: 'EditorialBanner',
  ctaText?: Maybe<Scalars['String']>,
  ctaUrl?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  imageUrl?: Maybe<Scalars['String']>,
  subTitle?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
};

export type EditorialItem = {
   __typename?: 'EditorialItem',
  ctaText?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  imageUrl?: Maybe<Scalars['String']>,
  showMoreUrl?: Maybe<Scalars['String']>,
  subTitle?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
};

export type EditorialList = {
   __typename?: 'EditorialList',
  description?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  numberOfItems?: Maybe<Scalars['Int']>,
  showMoreLink?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Scalars['String']>,
  items?: Maybe<Array<Maybe<ViewableTwo>>>,
  assets?: Maybe<Array<Maybe<Asset>>>,
  viewables?: Maybe<Array<Maybe<Viewable>>>,
  allAssets?: Maybe<Array<Maybe<Asset>>>,
};


export type EditorialListItemsArgs = {
  limit?: Maybe<Scalars['Int']>
};


export type EditorialListAssetsArgs = {
  limit?: Maybe<Scalars['Int']>
};


export type EditorialListViewablesArgs = {
  limit?: Maybe<Scalars['Int']>
};

export type Epg = {
   __typename?: 'Epg',
  days?: Maybe<Array<Maybe<EpgDay>>>,
};

export type EpgChannel = {
   __typename?: 'EpgChannel',
  asset?: Maybe<Asset>,
  channelId?: Maybe<Scalars['Int']>,
  currentSchedule?: Maybe<EpgSchedule>,
  darkLogo?: Maybe<Scalars['String']>,
  logo?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  schedules?: Maybe<Array<Maybe<EpgSchedule>>>,
  title?: Maybe<Scalars['String']>,
};

export type EpgDay = {
   __typename?: 'EpgDay',
  channels?: Maybe<Array<Maybe<EpgChannel>>>,
};

export type EpgProgram = {
   __typename?: 'EpgProgram',
  category?: Maybe<Scalars['String']>,
  duration?: Maybe<Scalars['Int']>,
  episodeNumber?: Maybe<Scalars['Int']>,
  image?: Maybe<Scalars['String']>,
  imageId?: Maybe<Scalars['String']>,
  programId?: Maybe<Scalars['String']>,
  seasonNumber?: Maybe<Scalars['Int']>,
  shortSynopsis?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
};

export type EpgSchedule = {
   __typename?: 'EpgSchedule',
  asset?: Maybe<Asset>,
  assetId?: Maybe<Scalars['String']>,
  calendarDate?: Maybe<Scalars['String']>,
  endDate?: Maybe<Scalars['String']>,
  isLive?: Maybe<Scalars['Boolean']>,
  isPremiere?: Maybe<Scalars['Boolean']>,
  nextStart?: Maybe<Scalars['String']>,
  program?: Maybe<EpgProgram>,
  progress?: Maybe<Scalars['Int']>,
  scheduleId?: Maybe<Scalars['Int']>,
  startDate?: Maybe<Scalars['String']>,
  startIndex: Scalars['Boolean'],
};

export type Episode = Playable & ViewableTwo & Resumable & {
   __typename?: 'Episode',
  id: Scalars['ID'],
  title?: Maybe<Scalars['String']>,
  image2x3?: Maybe<Scalars['String']>,
  image16x9?: Maybe<Scalars['String']>,
  relativeUrl?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  genre?: Maybe<Scalars['String']>,
  year?: Maybe<Scalars['Int']>,
  promotedSubscription?: Maybe<Product>,
  promotedSubscriptions?: Maybe<Array<Maybe<Product>>>,
  promotedPpv?: Maybe<Product>,
  readableDuration?: Maybe<Scalars['String']>,
  startTime?: Maybe<Scalars['String']>,
  similars?: Maybe<SimilarItems>,
  daysLeftInService?: Maybe<Scalars['Int']>,
  readableAvailability: Scalars['String'],
  descriptionExtended?: Maybe<Scalars['String']>,
  shouldReportProgress?: Maybe<Scalars['Boolean']>,
  /** Indicates if the playable can be downloaded. Dont overdo this query. Its heavy to fetch. */
  downloadable: Scalars['Boolean'],
  hasAccess: Scalars['Boolean'],
  actors?: Maybe<Array<Maybe<Scalars['String']>>>,
  broadcastingInfo?: Maybe<Scalars['String']>,
  airedTimeInfo?: Maybe<Scalars['String']>,
  directors?: Maybe<Array<Maybe<Scalars['String']>>>,
  episodeNumber?: Maybe<Scalars['Int']>,
  liveNow: Scalars['Boolean'],
  nextEpisode?: Maybe<Episode>,
  progress?: Maybe<Progress>,
  season?: Maybe<SeriesSeason>,
  series?: Maybe<Series>,
};


export type EpisodeActorsArgs = {
  limit?: Maybe<Scalars['Int']>
};

export type Favorite = Asset | Series;

export type FavoriteList = {
   __typename?: 'FavoriteList',
  id: Scalars['ID'],
  items?: Maybe<Array<Maybe<FavoriteTwo>>>,
};

export type Favorites = {
   __typename?: 'Favorites',
  id: Scalars['ID'],
  items?: Maybe<Array<Maybe<Favorite>>>,
};

export type FavoriteTwo = {
  id: Scalars['ID'],
  title?: Maybe<Scalars['String']>,
  image2x3?: Maybe<Scalars['String']>,
  image16x9?: Maybe<Scalars['String']>,
  relativeUrl?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  genre?: Maybe<Scalars['String']>,
  year?: Maybe<Scalars['Int']>,
  promotedSubscription?: Maybe<Product>,
  promotedSubscriptions?: Maybe<Array<Maybe<Product>>>,
  promotedPpv?: Maybe<Product>,
  favorite?: Maybe<Scalars['Boolean']>,
};

export type Filter = {
   __typename?: 'Filter',
  count?: Maybe<Scalars['Int']>,
  title: Scalars['String'],
  value: Scalars['String'],
};

export type FinalizeKlarnaPurchasePayload = {
   __typename?: 'FinalizeKlarnaPurchasePayload',
  order?: Maybe<Order>,
  redirectUrl?: Maybe<Scalars['String']>,
};

export type GapFiller = TimeSlot & {
   __typename?: 'GapFiller',
  endTime?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['ID']>,
  image?: Maybe<Scalars['String']>,
  nextAvailableProgram?: Maybe<Program>,
  nowPlaying: Scalars['Boolean'],
  progress?: Maybe<Scalars['Int']>,
  startTime?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
};

export type Images = {
   __typename?: 'Images',
  main16x9?: Maybe<Scalars['String']>,
  main16x7?: Maybe<Scalars['String']>,
  main4x3?: Maybe<Scalars['String']>,
  main16x9Annotated?: Maybe<Scalars['String']>,
  main1x1?: Maybe<Scalars['String']>,
};

export type Imdb = {
   __typename?: 'Imdb',
  id: Scalars['ID'],
  rating?: Maybe<Scalars['Float']>,
  ratingCount?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
};

export type KidsContinueWatching = {
   __typename?: 'KidsContinueWatching',
  items?: Maybe<Array<Maybe<Resumable>>>,
  title?: Maybe<Scalars['String']>,
  assets?: Maybe<Array<Maybe<Asset>>>,
};

export type KidsMode = {
   __typename?: 'KidsMode',
  continueWatching?: Maybe<KidsContinueWatching>,
  panels?: Maybe<Array<Maybe<Panel>>>,
  myList?: Maybe<MyList>,
};

export type Klarna = {
   __typename?: 'Klarna',
  clientToken?: Maybe<Scalars['String']>,
  sessionId?: Maybe<Scalars['ID']>,
};

export type KlarnaPaymentMethod = {
   __typename?: 'KlarnaPaymentMethod',
  nextChargeDate?: Maybe<Scalars['String']>,
};

export type LiveSportsList = {
   __typename?: 'LiveSportsList',
  id: Scalars['ID'],
  title?: Maybe<Scalars['String']>,
  items?: Maybe<Array<Maybe<SportEvent>>>,
  assets?: Maybe<Array<Maybe<Asset>>>,
};

export type Message = {
   __typename?: 'Message',
  content: Scalars['String'],
  id: Scalars['ID'],
  /** If this is true, you can remove it with the removeMessage mutation */
  removable?: Maybe<Scalars['Boolean']>,
  url?: Maybe<Scalars['String']>,
};

export type Movie = Playable & ViewableTwo & FavoriteTwo & Resumable & {
   __typename?: 'Movie',
  id: Scalars['ID'],
  title?: Maybe<Scalars['String']>,
  image2x3?: Maybe<Scalars['String']>,
  image16x9?: Maybe<Scalars['String']>,
  relativeUrl?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  genre?: Maybe<Scalars['String']>,
  year?: Maybe<Scalars['Int']>,
  promotedSubscription?: Maybe<Product>,
  promotedSubscriptions?: Maybe<Array<Maybe<Product>>>,
  promotedPpv?: Maybe<Product>,
  readableDuration?: Maybe<Scalars['String']>,
  startTime?: Maybe<Scalars['String']>,
  similars?: Maybe<SimilarItems>,
  daysLeftInService?: Maybe<Scalars['Int']>,
  readableAvailability: Scalars['String'],
  descriptionExtended?: Maybe<Scalars['String']>,
  shouldReportProgress?: Maybe<Scalars['Boolean']>,
  /** Indicates if the playable can be downloaded. Dont overdo this query. Its heavy to fetch. */
  downloadable: Scalars['Boolean'],
  hasAccess: Scalars['Boolean'],
  image16x7?: Maybe<Scalars['String']>,
  imdb?: Maybe<Imdb>,
  directors?: Maybe<Array<Maybe<Scalars['String']>>>,
  progress?: Maybe<Progress>,
  favorite?: Maybe<Scalars['Boolean']>,
  actors?: Maybe<Array<Maybe<Scalars['String']>>>,
  parentalGuidance?: Maybe<Scalars['String']>,
  displayCountry?: Maybe<Scalars['String']>,
};


export type MovieActorsArgs = {
  limit?: Maybe<Scalars['Int']>
};

export type Mutation = {
   __typename?: 'Mutation',
  addMovieToFavoriteList?: Maybe<FavoriteList>,
  addSeriesToFavoriteList?: Maybe<FavoriteList>,
  removeMovieFromFavoriteList?: Maybe<FavoriteList>,
  removeSeriesFromFavoriteList?: Maybe<FavoriteList>,
  markAsSeen2?: Maybe<Resumable>,
  markListAsSeen?: Maybe<Array<Maybe<Asset>>>,
  removeFromContinueWatching?: Maybe<Scalars['ID']>,
  removeListFromContinueWatching?: Maybe<Array<Maybe<Scalars['ID']>>>,
  removeMessage?: Maybe<Scalars['ID']>,
  activateClient?: Maybe<Scalars['Boolean']>,
  activateTveUser?: Maybe<Scalars['String']>,
  createUser?: Maybe<CreateUserPayload>,
  logoutAll?: Maybe<Scalars['String']>,
  sendForgotPasswordEmail?: Maybe<Scalars['String']>,
  updateEmail?: Maybe<UpdateUserPayload>,
  updatePassword?: Maybe<Scalars['String']>,
  updatePasswordWithToken?: Maybe<Scalars['String']>,
  updateUser?: Maybe<UpdateUserPayload>,
  changeProductV2?: Maybe<OrderCollection>,
  finalizeKlarnaPurchase?: Maybe<FinalizeKlarnaPurchasePayload>,
  initPurchase?: Maybe<Purchase>,
  reactivateActiveOrderV2?: Maybe<Order>,
  resumeOrder?: Maybe<OrderCollection>,
  terminateOrderV2?: Maybe<Order>,
  createProfiles?: Maybe<User>,
  updateProfile?: Maybe<User>,
  removeProfile?: Maybe<User>,
  assumeProfile?: Maybe<Scalars['String']>,
  terminateOrder?: Maybe<Order>,
  addMovieFavorite?: Maybe<Asset>,
  addSeriesFavorite?: Maybe<Series>,
  addToFavorite?: Maybe<Scalars['String']>,
  logoutOther?: Maybe<Scalars['String']>,
  markAsSeenContinueWatching?: Maybe<ContinueWatchingItem>,
  removeFromFavorite?: Maybe<Scalars['String']>,
  removeMovieFavorite?: Maybe<Asset>,
  removeSeriesFavorite?: Maybe<Series>,
  refreshMe?: Maybe<Scalars['Boolean']>,
};


export type MutationAddMovieToFavoriteListArgs = {
  id: Scalars['ID']
};


export type MutationAddSeriesToFavoriteListArgs = {
  id: Scalars['ID']
};


export type MutationRemoveMovieFromFavoriteListArgs = {
  id: Scalars['ID']
};


export type MutationRemoveSeriesFromFavoriteListArgs = {
  id: Scalars['ID']
};


export type MutationMarkAsSeen2Args = {
  id: Scalars['ID']
};


export type MutationMarkListAsSeenArgs = {
  ids: Array<Maybe<Scalars['ID']>>
};


export type MutationRemoveFromContinueWatchingArgs = {
  id?: Maybe<Scalars['ID']>
};


export type MutationRemoveListFromContinueWatchingArgs = {
  ids: Array<Maybe<Scalars['ID']>>
};


export type MutationRemoveMessageArgs = {
  id: Scalars['ID']
};


export type MutationActivateClientArgs = {
  code?: Maybe<Scalars['String']>,
  token?: Maybe<Scalars['String']>
};


export type MutationActivateTveUserArgs = {
  userId: Scalars['String'],
  tveUsername: Scalars['String'],
  tvePassword: Scalars['String'],
  operator: Scalars['String']
};


export type MutationCreateUserArgs = {
  username: Scalars['String'],
  password: Scalars['String'],
  firstname: Scalars['String'],
  lastname: Scalars['String'],
  acceptedTerms: Scalars['String'],
  newsletter?: Maybe<Scalars['Boolean']>
};


export type MutationSendForgotPasswordEmailArgs = {
  email: Scalars['String']
};


export type MutationUpdateEmailArgs = {
  newEmail: Scalars['String'],
  username: Scalars['String'],
  password: Scalars['String']
};


export type MutationUpdatePasswordArgs = {
  oldPassword: Scalars['String'],
  newPassword: Scalars['String'],
  logoutOther?: Maybe<Scalars['Boolean']>
};


export type MutationUpdatePasswordWithTokenArgs = {
  newPassword: Scalars['String'],
  token: Scalars['String']
};


export type MutationUpdateUserArgs = {
  firstname?: Maybe<Scalars['String']>,
  lastname?: Maybe<Scalars['String']>,
  acceptedTerms?: Maybe<Scalars['String']>
};


export type MutationChangeProductV2Args = {
  orderId: Scalars['ID'],
  productId: Scalars['ID'],
  password?: Maybe<Scalars['String']>
};


export type MutationFinalizeKlarnaPurchaseArgs = {
  authToken: Scalars['String'],
  sessionId: Scalars['String'],
  assetId?: Maybe<Scalars['ID']>,
  productId: Scalars['ID'],
  voucherCode?: Maybe<Scalars['String']>,
  referrer?: Maybe<Scalars['String']>
};


export type MutationInitPurchaseArgs = {
  checkoutId?: Maybe<Scalars['String']>,
  assetId?: Maybe<Scalars['ID']>,
  dibsSuccessCallbackBaseUrl?: Maybe<Scalars['String']>,
  dibsErrorCallbackBaseUrl?: Maybe<Scalars['String']>,
  referrer?: Maybe<Scalars['String']>
};


export type MutationReactivateActiveOrderV2Args = {
  orderId: Scalars['ID']
};


export type MutationResumeOrderArgs = {
  futureOrderId: Scalars['ID'],
  password: Scalars['String']
};


export type MutationTerminateOrderV2Args = {
  orderId: Scalars['ID'],
  password: Scalars['String']
};


export type MutationCreateProfilesArgs = {
  profiles?: Maybe<Array<Maybe<CreateProfilePayload>>>
};


export type MutationUpdateProfileArgs = {
  profileId: Scalars['String'],
  alias: Scalars['String'],
  avatarId: Scalars['String'],
  ageGroup: AgeGroup,
  themeId?: Maybe<Scalars['String']>
};


export type MutationRemoveProfileArgs = {
  profileId: Scalars['String']
};


export type MutationAssumeProfileArgs = {
  profileId: Scalars['String']
};


export type MutationTerminateOrderArgs = {
  orderId: Scalars['ID']
};


export type MutationAddMovieFavoriteArgs = {
  id?: Maybe<Scalars['ID']>,
  type?: Maybe<Scalars['String']>
};


export type MutationAddSeriesFavoriteArgs = {
  id?: Maybe<Scalars['ID']>,
  type?: Maybe<Scalars['String']>
};


export type MutationAddToFavoriteArgs = {
  id?: Maybe<Scalars['ID']>,
  type?: Maybe<Scalars['String']>
};


export type MutationMarkAsSeenContinueWatchingArgs = {
  id?: Maybe<Scalars['ID']>
};


export type MutationRemoveFromFavoriteArgs = {
  id?: Maybe<Scalars['ID']>,
  type?: Maybe<Scalars['String']>
};


export type MutationRemoveMovieFavoriteArgs = {
  id?: Maybe<Scalars['ID']>,
  type?: Maybe<Scalars['String']>
};


export type MutationRemoveSeriesFavoriteArgs = {
  id?: Maybe<Scalars['ID']>,
  type?: Maybe<Scalars['String']>
};

export type MyList = {
   __typename?: 'MyList',
  favoriteList?: Maybe<FavoriteList>,
  id: Scalars['ID'],
  title?: Maybe<Scalars['String']>,
  favorites?: Maybe<Favorites>,
};

export type NextContent = {
   __typename?: 'NextContent',
  autoplay?: Maybe<Asset>,
};

export type Operator = {
   __typename?: 'Operator',
  name?: Maybe<Scalars['String']>,
  supportInformation?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
};

export type Order = {
   __typename?: 'Order',
  accessEndDate?: Maybe<Scalars['String']>,
  active?: Maybe<Scalars['Boolean']>,
  asset?: Maybe<Asset>,
  changeableProducts?: Maybe<Array<Maybe<Product>>>,
  creditCardExpires?: Maybe<Scalars['String']>,
  creditCardNumber?: Maybe<Scalars['String']>,
  earliestEndDate?: Maybe<Scalars['String']>,
  endDate?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  isPausable?: Maybe<Scalars['Boolean']>,
  isPaused?: Maybe<Scalars['Boolean']>,
  isRenewable?: Maybe<Scalars['Boolean']>,
  isStoppable?: Maybe<Scalars['Boolean']>,
  localizedPrice?: Maybe<Scalars['String']>,
  nextChargeDate?: Maybe<Scalars['String']>,
  operator?: Maybe<Operator>,
  paymentMethod?: Maybe<PaymentMethod>,
  paymentType?: Maybe<Scalars['String']>,
  ppv?: Maybe<Scalars['Boolean']>,
  price?: Maybe<Scalars['Float']>,
  product?: Maybe<Product>,
  referrer?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
};

export type OrderCollection = {
   __typename?: 'OrderCollection',
  activeOrders?: Maybe<Array<Maybe<Order>>>,
  futureOrders?: Maybe<Array<Maybe<Order>>>,
};

export type Page = {
   __typename?: 'Page',
  /** 
 * gets panels for a page
   * 
   * productGroupIds, isLoggedIn and client are deprecated parameters and has no effect
 */
  panels?: Maybe<Array<Panel>>,
  subPages?: Maybe<Array<Maybe<SubPage>>>,
};


export type PagePanelsArgs = {
  skip?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  productGroupIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  isLoggedIn?: Maybe<Scalars['Boolean']>,
  client?: Maybe<ClientType>
};

/** SimilarsList is deprecated and will never be returned */
export type Panel = LiveSportsList | EditorialItem | EditorialAsset | EditorialList | EditorialBanner | AssetList | MyList | ShowCase | ContinueWatchingList | SportChannelList | SimilarsList;

export type PaymentMethod = {
   __typename?: 'PaymentMethod',
  localizedPrice?: Maybe<Scalars['String']>,
  localizedPriceDescription?: Maybe<Scalars['String']>,
  localizedRecurringPrice?: Maybe<Scalars['String']>,
  paymentId?: Maybe<Scalars['Int']>,
  paymentName?: Maybe<Scalars['String']>,
  initPrice?: Maybe<Scalars['Int']>,
};

export type PaymentOptions = Dibs | Klarna;

export type Playable = {
  id: Scalars['ID'],
  title?: Maybe<Scalars['String']>,
  image2x3?: Maybe<Scalars['String']>,
  image16x9?: Maybe<Scalars['String']>,
  relativeUrl?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  genre?: Maybe<Scalars['String']>,
  year?: Maybe<Scalars['Int']>,
  promotedSubscription?: Maybe<Product>,
  promotedSubscriptions?: Maybe<Array<Maybe<Product>>>,
  promotedPpv?: Maybe<Product>,
  readableDuration?: Maybe<Scalars['String']>,
  startTime?: Maybe<Scalars['String']>,
  similars?: Maybe<SimilarItems>,
  daysLeftInService?: Maybe<Scalars['Int']>,
  readableAvailability: Scalars['String'],
  descriptionExtended?: Maybe<Scalars['String']>,
  shouldReportProgress?: Maybe<Scalars['Boolean']>,
  /** Indicates if the playable can be downloaded. Dont overdo this query. Its heavy to fetch. */
  downloadable: Scalars['Boolean'],
  hasAccess: Scalars['Boolean'],
};

export type Product = {
   __typename?: 'Product',
  bindingTimeMonths?: Maybe<Scalars['Int']>,
  bindingTimeText?: Maybe<Scalars['String']>,
  buyable?: Maybe<Scalars['Boolean']>,
  ctaSubtext?: Maybe<Scalars['String']>,
  ctaText?: Maybe<Scalars['String']>,
  currency?: Maybe<Scalars['String']>,
  features?: Maybe<Array<Maybe<Scalars['String']>>>,
  fullPriceProductId?: Maybe<Scalars['Int']>,
  id: Scalars['Int'],
  isPauseProduct?: Maybe<Scalars['Boolean']>,
  isUpgrade?: Maybe<Scalars['Boolean']>,
  minimumPeriods?: Maybe<Scalars['Int']>,
  minimumCost?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  paymentMethod?: Maybe<PaymentMethod>,
  paymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>,
  paymentType?: Maybe<Scalars['String']>,
  ppv?: Maybe<Scalars['Boolean']>,
  price?: Maybe<Scalars['Int']>,
  priceDescription?: Maybe<Scalars['String']>,
  priceTitle?: Maybe<Scalars['String']>,
  priceSubtitle?: Maybe<Scalars['String']>,
  productGroupId: Scalars['Int'],
  startWatchingPath?: Maybe<Scalars['String']>,
  tax?: Maybe<Scalars['Float']>,
  trackingName?: Maybe<Scalars['String']>,
  visible?: Maybe<Scalars['Boolean']>,
  whereToPurchase?: Maybe<Url>,
};

export enum ProductLogoFileType {
  Svg = 'SVG',
  Png = 'PNG'
}

export type Profile = {
   __typename?: 'Profile',
  id: Scalars['ID'],
  alias: Scalars['String'],
  avatar: Avatar,
  theme?: Maybe<Theme>,
  ageGroup: AgeGroup,
  default?: Maybe<Scalars['Boolean']>,
  active?: Maybe<Scalars['Boolean']>,
};

/** Grouping of ssets related to the profiles. */
export type ProfileAssets = {
   __typename?: 'ProfileAssets',
  avatars: Array<Maybe<Avatar>>,
  themes: Array<Maybe<Theme>>,
};


/** Grouping of ssets related to the profiles. */
export type ProfileAssetsAvatarsArgs = {
  avatarFormat?: Maybe<AvatarFormat>
};

export type Program = TimeSlot & {
   __typename?: 'Program',
  description?: Maybe<Scalars['String']>,
  descriptionExtended?: Maybe<Scalars['String']>,
  endTime?: Maybe<Scalars['String']>,
  episodeLongText?: Maybe<Scalars['String']>,
  episodeShortText?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['ID']>,
  image?: Maybe<Scalars['String']>,
  isMovie?: Maybe<Scalars['Boolean']>,
  nextAvailableProgram?: Maybe<Program>,
  nowPlaying: Scalars['Boolean'],
  playable?: Maybe<Playable>,
  progress?: Maybe<Scalars['Int']>,
  startTime?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  asset?: Maybe<Asset>,
};

export type ProgramFamily = {
   __typename?: 'ProgramFamily',
  id: Scalars['ID'],
  title: Scalars['String'],
};

export type ProgramFamilySeason = SeriesSeason & {
   __typename?: 'ProgramFamilySeason',
  brandId?: Maybe<Scalars['String']>,
  descriptionExtended?: Maybe<Scalars['String']>,
  episodes2?: Maybe<Array<Episode>>,
  numberOfEpisodes?: Maybe<Scalars['Int']>,
  seasonNumber?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
  episodes?: Maybe<Array<Asset>>,
};


export type ProgramFamilySeasonEpisodes2Args = {
  page?: Maybe<Scalars['Int']>,
  pageSize?: Maybe<Scalars['Int']>
};


export type ProgramFamilySeasonEpisodesArgs = {
  page?: Maybe<Scalars['Int']>,
  pageSize?: Maybe<Scalars['Int']>
};

export type Progress = {
   __typename?: 'Progress',
  id?: Maybe<Scalars['String']>,
  percent?: Maybe<Scalars['Int']>,
  position?: Maybe<Scalars['Int']>,
  remaining?: Maybe<Scalars['Int']>,
  duration?: Maybe<Scalars['Int']>,
  readableRemaining?: Maybe<Scalars['String']>,
};

export type PromotedProducts = {
   __typename?: 'PromotedProducts',
  ppv?: Maybe<Array<Maybe<Product>>>,
  subscriptions?: Maybe<Array<Maybe<Product>>>,
};

export type Purchase = {
   __typename?: 'Purchase',
  id: Scalars['ID'],
  paymentOptions?: Maybe<Array<Maybe<PaymentOptions>>>,
  product?: Maybe<Product>,
  valid?: Maybe<Scalars['Boolean']>,
};

export type Query = {
   __typename?: 'Query',
  article?: Maybe<Article>,
  asset?: Maybe<Asset>,
  cancellationData?: Maybe<Cancellation>,
  channel?: Maybe<Channel>,
  channels?: Maybe<Array<Maybe<Channel>>>,
  configuration?: Maybe<Scalars['String']>,
  continueWatching?: Maybe<ContinueWatching>,
  epg?: Maybe<Epg>,
  favorites2?: Maybe<FavoriteList>,
  kidsMode?: Maybe<KidsMode>,
  leagueOrCompetition?: Maybe<Sport>,
  page?: Maybe<Page>,
  panel?: Maybe<Panel>,
  playable?: Maybe<Playable>,
  profileAssets?: Maybe<ProfileAssets>,
  search?: Maybe<SearchResults>,
  series?: Maybe<Series>,
  sport?: Maybe<Sport>,
  sportEventDates?: Maybe<Array<Maybe<SportEventDay>>>,
  sportEventsByDate?: Maybe<Sport>,
  team?: Maybe<Sport>,
  terms?: Maybe<Terms>,
  topRated?: Maybe<Array<Maybe<Asset>>>,
  topShelf?: Maybe<ShowCase>,
  voucher?: Maybe<Voucher>,
  isPurchaseValid?: Maybe<Scalars['Boolean']>,
  order?: Maybe<Order>,
  product?: Maybe<Product>,
  messages?: Maybe<Array<Maybe<Message>>>,
  sessionMetadata?: Maybe<SessionMetadata>,
  user?: Maybe<User>,
  viewable?: Maybe<ViewableTwo>,
  assetSearch?: Maybe<AssetSearchResults>,
  favorites?: Maybe<Favorites>,
  contentPanel?: Maybe<ContentPanel>,
  userMessages?: Maybe<Array<Maybe<Message>>>,
};


export type QueryArticleArgs = {
  entryId: Scalars['ID']
};


export type QueryAssetArgs = {
  id: Scalars['ID']
};


export type QueryChannelArgs = {
  id: Scalars['ID'],
  idType?: Maybe<ChannelIdType>
};


export type QueryChannelsArgs = {
  productGroups?: Maybe<Array<Maybe<Scalars['String']>>>
};


export type QueryConfigurationArgs = {
  key?: Maybe<Scalars['String']>
};


export type QueryEpgArgs = {
  date?: Maybe<Scalars['String']>,
  productGroups?: Maybe<Array<Maybe<Scalars['String']>>>
};


export type QueryLeagueOrCompetitionArgs = {
  name?: Maybe<Scalars['String']>
};


export type QueryPageArgs = {
  id?: Maybe<Scalars['String']>
};


export type QueryPanelArgs = {
  id: Scalars['ID']
};


export type QueryPlayableArgs = {
  id: Scalars['ID']
};


export type QuerySearchArgs = {
  q?: Maybe<Scalars['String']>,
  tags?: Maybe<Scalars['String']>,
  limit?: Maybe<Scalars['Int']>,
  page?: Maybe<Scalars['Int']>,
  brandId?: Maybe<Scalars['String']>,
  season?: Maybe<Scalars['Int']>,
  order?: Maybe<Scalars['String']>,
  sortBy?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
  titleStartChar?: Maybe<Scalars['String']>,
  personName?: Maybe<Scalars['String']>
};


export type QuerySeriesArgs = {
  id: Scalars['ID']
};


export type QuerySportArgs = {
  sports?: Maybe<Array<Maybe<Scalars['String']>>>,
  productGroups?: Maybe<Array<Maybe<Scalars['String']>>>,
  ppvIds?: Maybe<Array<Maybe<Scalars['ID']>>>
};


export type QuerySportEventsByDateArgs = {
  date?: Maybe<Scalars['String']>,
  sports?: Maybe<Array<Maybe<Scalars['String']>>>,
  productGroups?: Maybe<Array<Maybe<Scalars['String']>>>,
  ppvIds?: Maybe<Array<Maybe<Scalars['ID']>>>
};


export type QueryTeamArgs = {
  teamNID?: Maybe<Scalars['String']>
};


export type QueryTopRatedArgs = {
  type?: Maybe<TopRatedType>,
  limit?: Maybe<Scalars['Int']>
};


export type QueryVoucherArgs = {
  voucherCode: Scalars['ID']
};


export type QueryIsPurchaseValidArgs = {
  checkoutType: Scalars['String'],
  checkoutIdentifier: Scalars['String'],
  assetId?: Maybe<Scalars['ID']>
};


export type QueryOrderArgs = {
  id: Scalars['ID']
};


export type QueryProductArgs = {
  id: Scalars['ID']
};


export type QueryViewableArgs = {
  id?: Maybe<Scalars['ID']>
};


export type QueryAssetSearchArgs = {
  q?: Maybe<Scalars['String']>,
  tags?: Maybe<Scalars['String']>,
  limit?: Maybe<Scalars['Int']>,
  page?: Maybe<Scalars['Int']>,
  brandId?: Maybe<Scalars['String']>,
  season?: Maybe<Scalars['Int']>,
  order?: Maybe<Scalars['String']>,
  sortBy?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
  titleStartChar?: Maybe<Scalars['String']>,
  personName?: Maybe<Scalars['String']>
};


export type QueryContentPanelArgs = {
  id: Scalars['ID']
};


export type QueryUserMessagesArgs = {
  client?: Maybe<Scalars['String']>
};

export type Response = {
   __typename?: 'Response',
  message?: Maybe<Scalars['String']>,
  order?: Maybe<Order>,
  status?: Maybe<Scalars['Boolean']>,
  statusCode?: Maybe<Scalars['Int']>,
};

export type Resumable = {
  id: Scalars['ID'],
  title?: Maybe<Scalars['String']>,
  image2x3?: Maybe<Scalars['String']>,
  image16x9?: Maybe<Scalars['String']>,
  relativeUrl?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  genre?: Maybe<Scalars['String']>,
  year?: Maybe<Scalars['Int']>,
  promotedSubscription?: Maybe<Product>,
  promotedSubscriptions?: Maybe<Array<Maybe<Product>>>,
  promotedPpv?: Maybe<Product>,
  readableDuration?: Maybe<Scalars['String']>,
  startTime?: Maybe<Scalars['String']>,
  similars?: Maybe<SimilarItems>,
  daysLeftInService?: Maybe<Scalars['Int']>,
  readableAvailability: Scalars['String'],
  descriptionExtended?: Maybe<Scalars['String']>,
  shouldReportProgress?: Maybe<Scalars['Boolean']>,
  /** Indicates if the playable can be downloaded. Dont overdo this query. Its heavy to fetch. */
  downloadable: Scalars['Boolean'],
  hasAccess: Scalars['Boolean'],
  progress?: Maybe<Progress>,
};

export type ScheduleDay = {
   __typename?: 'ScheduleDay',
  epgSchedules?: Maybe<Array<Maybe<EpgSchedule>>>,
  name?: Maybe<Scalars['String']>,
};

export type SearchResults = {
   __typename?: 'SearchResults',
  totalHits?: Maybe<Scalars['Int']>,
  items?: Maybe<Array<Maybe<ViewableTwo>>>,
  viewables?: Maybe<Array<Maybe<Viewable>>>,
};

export type Season = {
   __typename?: 'Season',
  brandId?: Maybe<Scalars['String']>,
  descriptionExtended?: Maybe<Scalars['String']>,
  episodes?: Maybe<Array<Asset>>,
  numberOfEpisodes?: Maybe<Scalars['Int']>,
  order?: Maybe<Scalars['String']>,
  seasonNumber?: Maybe<Scalars['Int']>,
};


export type SeasonEpisodesArgs = {
  page?: Maybe<Scalars['Int']>,
  pageSize?: Maybe<Scalars['Int']>
};

export type Section = {
   __typename?: 'Section',
  calenderIndex?: Maybe<Scalars['Int']>,
  date?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['ID']>,
  events?: Maybe<Array<Maybe<SportEvent>>>,
  startIndex: Scalars['Boolean'],
  title?: Maybe<Scalars['String']>,
  type?: Maybe<SectionType>,
  items?: Maybe<Array<SportSectionItem>>,
  sectionItems?: Maybe<Array<SectionItem>>,
};

export type SectionItem = Asset | TimeDivider;

export enum SectionType {
  Day = 'DAY',
  EarlierToday = 'EARLIER_TODAY',
  LaterToday = 'LATER_TODAY',
  Live = 'LIVE',
  Tomorrow = 'TOMORROW',
  Upcoming = 'UPCOMING',
  Yesterday = 'YESTERDAY'
}

export type Series = ViewableTwo & FavoriteTwo & {
   __typename?: 'Series',
  actors?: Maybe<Array<Scalars['String']>>,
  category?: Maybe<Scalars['String']>,
  broadcastingInfo?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  descriptionExtended?: Maybe<Scalars['String']>,
  displayCountry?: Maybe<Scalars['String']>,
  favorite?: Maybe<Scalars['Boolean']>,
  genre?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  image16x9?: Maybe<Scalars['String']>,
  image16x7?: Maybe<Scalars['String']>,
  image1x1?: Maybe<Scalars['String']>,
  image2x3?: Maybe<Scalars['String']>,
  imdb?: Maybe<Imdb>,
  parentalGuidance?: Maybe<Scalars['String']>,
  programFamily?: Maybe<ProgramFamily>,
  promotedSubscription?: Maybe<Product>,
  promotedSubscriptions?: Maybe<Array<Maybe<Product>>>,
  promotedPpv?: Maybe<Product>,
  relativeUrl?: Maybe<Scalars['String']>,
  readableNumberOfSeasons?: Maybe<Scalars['String']>,
  seriesSeason?: Maybe<SeriesSeason>,
  seriesSeasons?: Maybe<Array<SeriesSeason>>,
  suggestedEpisode2?: Maybe<Episode>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
  webview?: Maybe<Scalars['String']>,
  year?: Maybe<Scalars['Int']>,
  hasAccess: Scalars['Boolean'],
  poster?: Maybe<Scalars['String']>,
  landscape?: Maybe<Scalars['String']>,
  season?: Maybe<Season>,
  seasons?: Maybe<Array<Season>>,
  seasonsUnsorted?: Maybe<Array<Season>>,
  suggestedEpisode?: Maybe<Asset>,
};


export type SeriesActorsArgs = {
  limit?: Maybe<Scalars['Int']>
};


export type SeriesSeriesSeasonArgs = {
  seasonNumber: Scalars['Int']
};


export type SeriesSeasonArgs = {
  seasonNumber: Scalars['Int']
};

export type SeriesSeason = {
  brandId?: Maybe<Scalars['String']>,
  descriptionExtended?: Maybe<Scalars['String']>,
  episodes2?: Maybe<Array<Episode>>,
  numberOfEpisodes?: Maybe<Scalars['Int']>,
  seasonNumber?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
  episodes?: Maybe<Array<Asset>>,
};


export type SeriesSeasonEpisodes2Args = {
  page?: Maybe<Scalars['Int']>,
  pageSize?: Maybe<Scalars['Int']>
};


export type SeriesSeasonEpisodesArgs = {
  page?: Maybe<Scalars['Int']>,
  pageSize?: Maybe<Scalars['Int']>
};

export type SessionMetadata = {
   __typename?: 'SessionMetadata',
  ip?: Maybe<Scalars['String']>,
  isInternalTraffic?: Maybe<Scalars['Boolean']>,
};

export type SessionOrder = {
   __typename?: 'SessionOrder',
  assetId?: Maybe<Scalars['ID']>,
  id?: Maybe<Scalars['ID']>,
  isPaused?: Maybe<Scalars['Boolean']>,
  ppv?: Maybe<Scalars['Boolean']>,
  product?: Maybe<SessionProduct>,
};

export type SessionProduct = {
   __typename?: 'SessionProduct',
  id?: Maybe<Scalars['Int']>,
  productGroupId?: Maybe<Scalars['Int']>,
};

export type ShowCase = {
   __typename?: 'ShowCase',
  id: Scalars['ID'],
  items?: Maybe<Array<Maybe<ShowCaseItem>>>,
};

export type ShowCaseItem = {
   __typename?: 'ShowCaseItem',
  description?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  imageUrl?: Maybe<Scalars['String']>,
  showMoreUrl?: Maybe<Scalars['String']>,
  subTitle?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  item?: Maybe<ViewableTwo>,
  asset?: Maybe<Asset>,
  viewable?: Maybe<Viewable>,
};

export type SimilarItems = {
   __typename?: 'SimilarItems',
  title?: Maybe<Scalars['String']>,
  items?: Maybe<Array<Maybe<ViewableTwo>>>,
};

export type Similars = {
   __typename?: 'Similars',
  assets?: Maybe<Array<Maybe<Viewable>>>,
  title?: Maybe<Scalars['String']>,
};

/** Deprecated */
export type SimilarsList = {
   __typename?: 'SimilarsList',
  id: Scalars['ID'],
  similars?: Maybe<Similars>,
};

export type Sport = {
   __typename?: 'Sport',
  allGenres?: Maybe<Array<Filter>>,
  currentGenres?: Maybe<Array<Filter>>,
  sections?: Maybe<Array<Section>>,
  totalHits?: Maybe<Scalars['Int']>,
  assets?: Maybe<Array<Maybe<Asset>>>,
};

export type SportChannelList = {
   __typename?: 'SportChannelList',
  channels?: Maybe<Array<Maybe<EpgChannel>>>,
  id: Scalars['ID'],
  title?: Maybe<Scalars['String']>,
};

export type SportEvent = Playable & ViewableTwo & {
   __typename?: 'SportEvent',
  id: Scalars['ID'],
  title?: Maybe<Scalars['String']>,
  image2x3?: Maybe<Scalars['String']>,
  image16x9?: Maybe<Scalars['String']>,
  relativeUrl?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  genre?: Maybe<Scalars['String']>,
  year?: Maybe<Scalars['Int']>,
  promotedSubscription?: Maybe<Product>,
  promotedSubscriptions?: Maybe<Array<Maybe<Product>>>,
  promotedPpv?: Maybe<Product>,
  readableDuration?: Maybe<Scalars['String']>,
  startTime?: Maybe<Scalars['String']>,
  similars?: Maybe<SimilarItems>,
  daysLeftInService?: Maybe<Scalars['Int']>,
  readableAvailability: Scalars['String'],
  descriptionExtended?: Maybe<Scalars['String']>,
  shouldReportProgress?: Maybe<Scalars['Boolean']>,
  /** Indicates if the playable can be downloaded. Dont overdo this query. Its heavy to fetch. */
  downloadable: Scalars['Boolean'],
  hasAccess: Scalars['Boolean'],
  arena?: Maybe<Scalars['String']>,
  commentators?: Maybe<Array<Maybe<Scalars['String']>>>,
  league?: Maybe<Scalars['String']>,
  liveNow: Scalars['Boolean'],
  logo?: Maybe<Scalars['String']>,
  productLogo?: Maybe<Scalars['String']>,
  readableStartTime?: Maybe<Scalars['String']>,
  subtitle?: Maybe<Scalars['String']>,
  awayteam?: Maybe<Team>,
  hometeam?: Maybe<Team>,
};


export type SportEventProductLogoArgs = {
  fileType?: Maybe<ProductLogoFileType>
};

export type SportEventDay = {
   __typename?: 'SportEventDay',
  label?: Maybe<Scalars['String']>,
  date?: Maybe<Scalars['String']>,
};

export type SportSectionItem = SportEvent | TimeDivider;

export type SubPage = {
   __typename?: 'SubPage',
  id?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
};

export type Team = {
   __typename?: 'Team',
  teamname?: Maybe<Scalars['String']>,
  logo?: Maybe<Scalars['String']>,
};

export type Terms = {
   __typename?: 'Terms',
  current?: Maybe<Scalars['String']>,
  date?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
};

export type Theme = {
   __typename?: 'Theme',
  id: Scalars['ID'],
  topImageUrl: Scalars['String'],
  topSquareImageUrl: Scalars['String'],
  shadowImageUrl: Scalars['String'],
  colors: ThemeColors,
};

export type ThemeColors = {
   __typename?: 'ThemeColors',
  primary: Scalars['String'],
  secondary: Scalars['String'],
  background: Scalars['String'],
  iconsAndText: Scalars['String'],
};

export type TimeDivider = {
   __typename?: 'TimeDivider',
  date?: Maybe<Scalars['String']>,
};

export type TimeSlot = {
  endTime?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['ID']>,
  image?: Maybe<Scalars['String']>,
  nextAvailableProgram?: Maybe<Program>,
  nowPlaying: Scalars['Boolean'],
  progress?: Maybe<Scalars['Int']>,
  startTime?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
};

export enum TopRatedType {
  Movie = 'Movie',
  Series = 'Series'
}

export type UpdateUserPayload = {
   __typename?: 'UpdateUserPayload',
  user?: Maybe<User>,
};

export type Url = {
   __typename?: 'Url',
  host?: Maybe<Scalars['String']>,
  href?: Maybe<Scalars['String']>,
  pathname?: Maybe<Scalars['String']>,
  website?: Maybe<Website>,
};

export type User = {
   __typename?: 'User',
  acceptedTerms?: Maybe<Scalars['String']>,
  activeOrders?: Maybe<Array<Maybe<SessionOrder>>>,
  email?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  lastName?: Maybe<Scalars['String']>,
  orders?: Maybe<OrderCollection>,
  paymentMethod?: Maybe<UserPaymentMethod>,
  operator?: Maybe<Scalars['String']>,
  currentProfile?: Maybe<Profile>,
  profiles?: Maybe<Array<Maybe<Profile>>>,
  availableAvatars?: Maybe<Array<Maybe<Avatar>>>,
};


export type UserCurrentProfileArgs = {
  avatarFormat?: Maybe<AvatarFormat>
};


export type UserProfilesArgs = {
  avatarFormat?: Maybe<AvatarFormat>
};


export type UserAvailableAvatarsArgs = {
  avatarFormat?: Maybe<AvatarFormat>
};

export type UserPaymentMethod = KlarnaPaymentMethod | CreditCardPaymentMethod | ApplePaymentMethod;

export type Viewable = Asset | Series;

export type ViewableTwo = {
  id: Scalars['ID'],
  title?: Maybe<Scalars['String']>,
  image2x3?: Maybe<Scalars['String']>,
  image16x9?: Maybe<Scalars['String']>,
  relativeUrl?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  genre?: Maybe<Scalars['String']>,
  year?: Maybe<Scalars['Int']>,
  promotedSubscription?: Maybe<Product>,
  promotedSubscriptions?: Maybe<Array<Maybe<Product>>>,
  promotedPpv?: Maybe<Product>,
};

export type Voucher = {
   __typename?: 'Voucher',
  code?: Maybe<Scalars['String']>,
  pool?: Maybe<Scalars['String']>,
  poolName?: Maybe<Scalars['String']>,
  product?: Maybe<Product>,
  valid?: Maybe<Scalars['Boolean']>,
};

export enum Website {
  Www = 'WWW',
  Checkout = 'CHECKOUT'
}

export type AssumeProfileMutationVariables = {
  profileId: Scalars['String']
};


export type AssumeProfileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assumeProfile'>
);

export type ChannelsQueryVariables = {};


export type ChannelsQuery = (
  { __typename?: 'Query' }
  & { channels: Maybe<Array<Maybe<(
    { __typename?: 'Channel' }
    & Pick<Channel, 'title' | 'assetId' | 'logo'>
    & { nowPlaying: Maybe<(
      { __typename?: 'Program' }
      & Pick<Program, 'description' | 'id' | 'nowPlaying' | 'endTime' | 'title' | 'image'>
    ) | (
      { __typename?: 'GapFiller' }
      & Pick<GapFiller, 'id' | 'nowPlaying' | 'endTime' | 'title' | 'image'>
    )> }
  )>>> }
);

type PageAssetListItem_Series_Fragment = (
  { __typename?: 'Series' }
  & Pick<Series, 'id' | 'description' | 'title' | 'image2x3' | 'image16x9' | 'year' | 'genre'>
);

type PageAssetListItem_Episode_Fragment = (
  { __typename?: 'Episode' }
  & Pick<Episode, 'id' | 'description' | 'title' | 'image2x3' | 'image16x9' | 'year' | 'genre'>
  & { series: Maybe<(
    { __typename?: 'Series' }
    & Pick<Series, 'id' | 'title'>
  )> }
);

type PageAssetListItem_SportEvent_Fragment = (
  { __typename?: 'SportEvent' }
  & Pick<SportEvent, 'id' | 'description' | 'title' | 'image2x3' | 'image16x9' | 'year' | 'genre'>
);

type PageAssetListItem_Clip_Fragment = (
  { __typename?: 'Clip' }
  & Pick<Clip, 'id' | 'description' | 'title' | 'image2x3' | 'image16x9' | 'year' | 'genre'>
);

type PageAssetListItem_Movie_Fragment = (
  { __typename?: 'Movie' }
  & Pick<Movie, 'id' | 'description' | 'title' | 'image2x3' | 'image16x9' | 'year' | 'genre'>
);

export type PageAssetListItemFragment = PageAssetListItem_Series_Fragment | PageAssetListItem_Episode_Fragment | PageAssetListItem_SportEvent_Fragment | PageAssetListItem_Clip_Fragment | PageAssetListItem_Movie_Fragment;

export type PageListSportListItemFragment = (
  { __typename?: 'SportEvent' }
  & Pick<SportEvent, 'id' | 'description' | 'title' | 'image2x3' | 'image16x9' | 'genre' | 'year'>
);

export type PageAssetListFragment = (
  { __typename?: 'AssetList' }
  & Pick<AssetList, 'id' | 'title'>
  & { items: Maybe<Array<Maybe<(
    { __typename?: 'Series' }
    & PageAssetListItem_Series_Fragment
  ) | (
    { __typename?: 'Episode' }
    & PageAssetListItem_Episode_Fragment
  ) | (
    { __typename?: 'SportEvent' }
    & PageAssetListItem_SportEvent_Fragment
  ) | (
    { __typename?: 'Clip' }
    & PageAssetListItem_Clip_Fragment
  ) | (
    { __typename?: 'Movie' }
    & PageAssetListItem_Movie_Fragment
  )>>> }
);

export type EditorialListFragment = (
  { __typename?: 'EditorialList' }
  & Pick<EditorialList, 'id' | 'title'>
  & { items: Maybe<Array<Maybe<(
    { __typename?: 'Series' }
    & PageAssetListItem_Series_Fragment
  ) | (
    { __typename?: 'Episode' }
    & PageAssetListItem_Episode_Fragment
  ) | (
    { __typename?: 'SportEvent' }
    & PageAssetListItem_SportEvent_Fragment
  ) | (
    { __typename?: 'Clip' }
    & PageAssetListItem_Clip_Fragment
  ) | (
    { __typename?: 'Movie' }
    & PageAssetListItem_Movie_Fragment
  )>>> }
);

export type LiveSportsListFragment = (
  { __typename?: 'LiveSportsList' }
  & Pick<LiveSportsList, 'id' | 'title'>
  & { items: Maybe<Array<Maybe<(
    { __typename?: 'SportEvent' }
    & PageListSportListItemFragment
  )>>> }
);

export type PageQueryVariables = {
  id: Scalars['String'],
  limit: Scalars['Int']
};


export type PageQuery = (
  { __typename?: 'Query' }
  & { page: Maybe<(
    { __typename?: 'Page' }
    & { panels: Maybe<Array<(
      { __typename?: 'LiveSportsList' }
      & LiveSportsListFragment
    ) | { __typename?: 'EditorialItem' } | { __typename?: 'EditorialAsset' } | (
      { __typename?: 'EditorialList' }
      & EditorialListFragment
    ) | { __typename?: 'EditorialBanner' } | (
      { __typename?: 'AssetList' }
      & PageAssetListFragment
    ) | { __typename?: 'MyList' } | { __typename?: 'ShowCase' } | { __typename?: 'ContinueWatchingList' } | { __typename?: 'SportChannelList' } | { __typename?: 'SimilarsList' }>> }
  )> }
);

export type PlayableQueryVariables = {
  id: Scalars['ID']
};


export type PlayableQuery = (
  { __typename?: 'Query' }
  & { playable: Maybe<(
    { __typename?: 'Episode' }
    & Pick<Episode, 'title' | 'descriptionExtended'>
    & { progress: Maybe<(
      { __typename?: 'Progress' }
      & Pick<Progress, 'position'>
    )>, nextEpisode: Maybe<(
      { __typename?: 'Episode' }
      & Pick<Episode, 'id' | 'title' | 'image16x9' | 'descriptionExtended'>
    )> }
  ) | (
    { __typename?: 'SportEvent' }
    & Pick<SportEvent, 'title' | 'descriptionExtended'>
  ) | (
    { __typename?: 'Clip' }
    & Pick<Clip, 'title' | 'descriptionExtended'>
  ) | (
    { __typename?: 'Movie' }
    & Pick<Movie, 'title' | 'descriptionExtended'>
    & { progress: Maybe<(
      { __typename?: 'Progress' }
      & Pick<Progress, 'position'>
    )> }
  )> }
);

export type SeasonQueryVariables = {
  seriesId: Scalars['ID'],
  seasonNumber: Scalars['Int']
};


export type SeasonQuery = (
  { __typename?: 'Query' }
  & { series: Maybe<(
    { __typename?: 'Series' }
    & { seriesSeasons: Maybe<Array<(
      { __typename?: 'BrandSeason' }
      & Pick<BrandSeason, 'seasonNumber' | 'title'>
    ) | (
      { __typename?: 'ProgramFamilySeason' }
      & Pick<ProgramFamilySeason, 'seasonNumber' | 'title'>
    )>>, seriesSeason: Maybe<(
      { __typename?: 'BrandSeason' }
      & { episodes: Maybe<Array<(
        { __typename?: 'Episode' }
        & Pick<Episode, 'id' | 'title' | 'readableDuration' | 'image16x9'>
        & { progress: Maybe<(
          { __typename?: 'Progress' }
          & Pick<Progress, 'id' | 'percent'>
        )> }
      )>> }
    ) | (
      { __typename?: 'ProgramFamilySeason' }
      & { episodes: Maybe<Array<(
        { __typename?: 'Episode' }
        & Pick<Episode, 'id' | 'title' | 'readableDuration' | 'image16x9'>
        & { progress: Maybe<(
          { __typename?: 'Progress' }
          & Pick<Progress, 'id' | 'percent'>
        )> }
      )>> }
    )> }
  )> }
);

export type UserQueryVariables = {};


export type UserQuery = (
  { __typename?: 'Query' }
  & { user: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email'>
    & { currentProfile: Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'alias'>
      & { avatar: (
        { __typename?: 'Avatar' }
        & Pick<Avatar, 'head'>
      ) }
    )>, profiles: Maybe<Array<Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'alias'>
      & { avatar: (
        { __typename?: 'Avatar' }
        & Pick<Avatar, 'head'>
      ) }
    )>>> }
  )> }
);

export type SeriesFragment = (
  { __typename?: 'Series' }
  & { suggestedEpisode: Maybe<(
    { __typename?: 'Episode' }
    & EpisodeFragment
  )> }
);

export type EpisodeFragment = (
  { __typename?: 'Episode' }
  & Pick<Episode, 'id' | 'title'>
  & { series: Maybe<(
    { __typename?: 'Series' }
    & Pick<Series, 'id' | 'title' | 'description' | 'image16x9' | 'year' | 'genre'>
  )>, season: Maybe<(
    { __typename?: 'BrandSeason' }
    & Pick<BrandSeason, 'title' | 'seasonNumber'>
  ) | (
    { __typename?: 'ProgramFamilySeason' }
    & Pick<ProgramFamilySeason, 'title' | 'seasonNumber'>
  )> }
);

export type MovieFragment = (
  { __typename?: 'Movie' }
  & Pick<Movie, 'id' | 'title' | 'description' | 'image16x9' | 'year' | 'genre'>
  & { similars: Maybe<(
    { __typename?: 'SimilarItems' }
    & Pick<SimilarItems, 'title'>
    & { items: Maybe<Array<Maybe<(
      { __typename?: 'Series' }
      & Pick<Series, 'id' | 'title' | 'image2x3'>
    ) | (
      { __typename?: 'Episode' }
      & Pick<Episode, 'id' | 'title' | 'image2x3'>
    ) | (
      { __typename?: 'SportEvent' }
      & Pick<SportEvent, 'id' | 'title' | 'image2x3'>
    ) | (
      { __typename?: 'Clip' }
      & Pick<Clip, 'id' | 'title' | 'image2x3'>
    ) | (
      { __typename?: 'Movie' }
      & Pick<Movie, 'id' | 'title' | 'image2x3'>
    )>>> }
  )> }
);

export type SportEventFragment = (
  { __typename?: 'SportEvent' }
  & Pick<SportEvent, 'id' | 'title' | 'description' | 'image16x9' | 'year' | 'genre'>
  & { similars: Maybe<(
    { __typename?: 'SimilarItems' }
    & Pick<SimilarItems, 'title'>
    & { items: Maybe<Array<Maybe<(
      { __typename?: 'Series' }
      & Pick<Series, 'id' | 'title' | 'image16x9'>
    ) | (
      { __typename?: 'Episode' }
      & Pick<Episode, 'id' | 'title' | 'image16x9'>
    ) | (
      { __typename?: 'SportEvent' }
      & Pick<SportEvent, 'id' | 'title' | 'image16x9'>
    ) | (
      { __typename?: 'Clip' }
      & Pick<Clip, 'id' | 'title' | 'image16x9'>
    ) | (
      { __typename?: 'Movie' }
      & Pick<Movie, 'id' | 'title' | 'image16x9'>
    )>>> }
  )> }
);

export type ClipFragment = (
  { __typename?: 'Clip' }
  & Pick<Clip, 'id' | 'title' | 'description' | 'image16x9' | 'year' | 'genre'>
  & { similars: Maybe<(
    { __typename?: 'SimilarItems' }
    & Pick<SimilarItems, 'title'>
    & { items: Maybe<Array<Maybe<(
      { __typename?: 'Series' }
      & Pick<Series, 'id' | 'title' | 'image16x9'>
    ) | (
      { __typename?: 'Episode' }
      & Pick<Episode, 'id' | 'title' | 'image16x9'>
    ) | (
      { __typename?: 'SportEvent' }
      & Pick<SportEvent, 'id' | 'title' | 'image16x9'>
    ) | (
      { __typename?: 'Clip' }
      & Pick<Clip, 'id' | 'title' | 'image16x9'>
    ) | (
      { __typename?: 'Movie' }
      & Pick<Movie, 'id' | 'title' | 'image16x9'>
    )>>> }
  )> }
);

type Viewable_Series_Fragment = (
  { __typename?: 'Series' }
  & SeriesFragment
);

type Viewable_Episode_Fragment = (
  { __typename?: 'Episode' }
  & EpisodeFragment
);

type Viewable_SportEvent_Fragment = (
  { __typename?: 'SportEvent' }
  & SportEventFragment
);

type Viewable_Clip_Fragment = (
  { __typename?: 'Clip' }
  & ClipFragment
);

type Viewable_Movie_Fragment = (
  { __typename?: 'Movie' }
  & MovieFragment
);

export type ViewableFragment = Viewable_Series_Fragment | Viewable_Episode_Fragment | Viewable_SportEvent_Fragment | Viewable_Clip_Fragment | Viewable_Movie_Fragment;

export type ViewableQueryVariables = {
  id: Scalars['ID']
};


export type ViewableQuery = (
  { __typename?: 'Query' }
  & { viewable: Maybe<(
    { __typename?: 'Series' }
    & Viewable_Series_Fragment
  ) | (
    { __typename?: 'Episode' }
    & Viewable_Episode_Fragment
  ) | (
    { __typename?: 'SportEvent' }
    & Viewable_SportEvent_Fragment
  ) | (
    { __typename?: 'Clip' }
    & Viewable_Clip_Fragment
  ) | (
    { __typename?: 'Movie' }
    & Viewable_Movie_Fragment
  )> }
);

export const PageAssetListItemFragmentDoc = gql`
    fragment PageAssetListItem on ViewableTwo {
  id
  description
  title
  image2x3
  image16x9
  year
  genre
  ... on Episode {
    series {
      id
      title
    }
  }
}
    `;
export const PageAssetListFragmentDoc = gql`
    fragment PageAssetList on AssetList {
  id
  title
  items(limit: 20) {
    ...PageAssetListItem
  }
}
    ${PageAssetListItemFragmentDoc}`;
export const EditorialListFragmentDoc = gql`
    fragment EditorialList on EditorialList {
  id
  title
  items(limit: 20) {
    ...PageAssetListItem
  }
}
    ${PageAssetListItemFragmentDoc}`;
export const PageListSportListItemFragmentDoc = gql`
    fragment PageListSportListItem on SportEvent {
  id
  description
  title
  image2x3
  image16x9
  genre
  year
}
    `;
export const LiveSportsListFragmentDoc = gql`
    fragment LiveSportsList on LiveSportsList {
  id
  title
  items {
    ...PageListSportListItem
  }
}
    ${PageListSportListItemFragmentDoc}`;
export const EpisodeFragmentDoc = gql`
    fragment Episode on Episode {
  id
  title
  series {
    id
    title
    description
    image16x9
    year
    genre
  }
  season {
    title
    seasonNumber
  }
}
    `;
export const SeriesFragmentDoc = gql`
    fragment Series on Series {
  suggestedEpisode: suggestedEpisode2 {
    ...Episode
  }
}
    ${EpisodeFragmentDoc}`;
export const MovieFragmentDoc = gql`
    fragment Movie on Movie {
  id
  title
  description
  image16x9
  year
  genre
  similars {
    title
    items {
      id
      title
      image2x3
    }
  }
}
    `;
export const SportEventFragmentDoc = gql`
    fragment SportEvent on SportEvent {
  id
  title
  description
  image16x9
  year
  genre
  similars {
    title
    items {
      id
      title
      image16x9
    }
  }
}
    `;
export const ClipFragmentDoc = gql`
    fragment Clip on Clip {
  id
  title
  description
  image16x9
  year
  genre
  similars {
    title
    items {
      id
      title
      image16x9
    }
  }
}
    `;
export const ViewableFragmentDoc = gql`
    fragment Viewable on ViewableTwo {
  ... on Series {
    ...Series
  }
  ... on Episode {
    ...Episode
  }
  ... on Movie {
    ...Movie
  }
  ... on SportEvent {
    ...SportEvent
  }
  ... on Clip {
    ...Clip
  }
}
    ${SeriesFragmentDoc}
${EpisodeFragmentDoc}
${MovieFragmentDoc}
${SportEventFragmentDoc}
${ClipFragmentDoc}`;
export const AssumeProfileDocument = gql`
    mutation assumeProfile($profileId: String!) {
  assumeProfile(profileId: $profileId)
}
    `;
export type AssumeProfileMutationFn = ApolloReactCommon.MutationFunction<AssumeProfileMutation, AssumeProfileMutationVariables>;

/**
 * __useAssumeProfileMutation__
 *
 * To run a mutation, you first call `useAssumeProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssumeProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assumeProfileMutation, { data, loading, error }] = useAssumeProfileMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useAssumeProfileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssumeProfileMutation, AssumeProfileMutationVariables>) {
        return ApolloReactHooks.useMutation<AssumeProfileMutation, AssumeProfileMutationVariables>(AssumeProfileDocument, baseOptions);
      }
export type AssumeProfileMutationHookResult = ReturnType<typeof useAssumeProfileMutation>;
export type AssumeProfileMutationResult = ApolloReactCommon.MutationResult<AssumeProfileMutation>;
export type AssumeProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<AssumeProfileMutation, AssumeProfileMutationVariables>;
export const ChannelsDocument = gql`
    query channels {
  channels {
    title
    assetId
    logo
    nowPlaying {
      id
      nowPlaying
      endTime
      title
      image
      ... on Program {
        description
      }
    }
  }
}
    `;

/**
 * __useChannelsQuery__
 *
 * To run a query within a React component, call `useChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useChannelsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ChannelsQuery, ChannelsQueryVariables>) {
        return ApolloReactHooks.useQuery<ChannelsQuery, ChannelsQueryVariables>(ChannelsDocument, baseOptions);
      }
export function useChannelsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChannelsQuery, ChannelsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ChannelsQuery, ChannelsQueryVariables>(ChannelsDocument, baseOptions);
        }
export type ChannelsQueryHookResult = ReturnType<typeof useChannelsQuery>;
export type ChannelsLazyQueryHookResult = ReturnType<typeof useChannelsLazyQuery>;
export type ChannelsQueryResult = ApolloReactCommon.QueryResult<ChannelsQuery, ChannelsQueryVariables>;
export const PageDocument = gql`
    query page($id: String!, $limit: Int!) {
  page(id: $id) {
    panels(limit: $limit) {
      ...PageAssetList
      ...LiveSportsList
      ...EditorialList
    }
  }
}
    ${PageAssetListFragmentDoc}
${LiveSportsListFragmentDoc}
${EditorialListFragmentDoc}`;

/**
 * __usePageQuery__
 *
 * To run a query within a React component, call `usePageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PageQuery, PageQueryVariables>) {
        return ApolloReactHooks.useQuery<PageQuery, PageQueryVariables>(PageDocument, baseOptions);
      }
export function usePageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PageQuery, PageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PageQuery, PageQueryVariables>(PageDocument, baseOptions);
        }
export type PageQueryHookResult = ReturnType<typeof usePageQuery>;
export type PageLazyQueryHookResult = ReturnType<typeof usePageLazyQuery>;
export type PageQueryResult = ApolloReactCommon.QueryResult<PageQuery, PageQueryVariables>;
export const PlayableDocument = gql`
    query playable($id: ID!) {
  playable(id: $id) {
    title
    descriptionExtended
    ... on Episode {
      progress {
        position
      }
      nextEpisode {
        id
        title
        image16x9
        descriptionExtended
      }
    }
    ... on Movie {
      progress {
        position
      }
    }
  }
}
    `;

/**
 * __usePlayableQuery__
 *
 * To run a query within a React component, call `usePlayableQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayableQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayableQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlayableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PlayableQuery, PlayableQueryVariables>) {
        return ApolloReactHooks.useQuery<PlayableQuery, PlayableQueryVariables>(PlayableDocument, baseOptions);
      }
export function usePlayableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PlayableQuery, PlayableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PlayableQuery, PlayableQueryVariables>(PlayableDocument, baseOptions);
        }
export type PlayableQueryHookResult = ReturnType<typeof usePlayableQuery>;
export type PlayableLazyQueryHookResult = ReturnType<typeof usePlayableLazyQuery>;
export type PlayableQueryResult = ApolloReactCommon.QueryResult<PlayableQuery, PlayableQueryVariables>;
export const SeasonDocument = gql`
    query season($seriesId: ID!, $seasonNumber: Int!) {
  series(id: $seriesId) {
    seriesSeasons {
      seasonNumber
      title
    }
    seriesSeason(seasonNumber: $seasonNumber) {
      episodes: episodes2 {
        id
        title
        readableDuration
        image16x9
        progress {
          id
          percent
        }
      }
    }
  }
}
    `;

/**
 * __useSeasonQuery__
 *
 * To run a query within a React component, call `useSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeasonQuery({
 *   variables: {
 *      seriesId: // value for 'seriesId'
 *      seasonNumber: // value for 'seasonNumber'
 *   },
 * });
 */
export function useSeasonQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SeasonQuery, SeasonQueryVariables>) {
        return ApolloReactHooks.useQuery<SeasonQuery, SeasonQueryVariables>(SeasonDocument, baseOptions);
      }
export function useSeasonLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SeasonQuery, SeasonQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SeasonQuery, SeasonQueryVariables>(SeasonDocument, baseOptions);
        }
export type SeasonQueryHookResult = ReturnType<typeof useSeasonQuery>;
export type SeasonLazyQueryHookResult = ReturnType<typeof useSeasonLazyQuery>;
export type SeasonQueryResult = ApolloReactCommon.QueryResult<SeasonQuery, SeasonQueryVariables>;
export const UserDocument = gql`
    query user {
  user {
    id
    email
    currentProfile {
      id
      alias
      avatar {
        head
      }
    }
    profiles {
      id
      alias
      avatar {
        head
      }
    }
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserQuery, UserQueryVariables>) {
        return ApolloReactHooks.useQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
      }
export function useUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = ApolloReactCommon.QueryResult<UserQuery, UserQueryVariables>;
export const ViewableDocument = gql`
    query viewable($id: ID!) {
  viewable(id: $id) {
    ...Viewable
  }
}
    ${ViewableFragmentDoc}`;

/**
 * __useViewableQuery__
 *
 * To run a query within a React component, call `useViewableQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewableQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewableQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useViewableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewableQuery, ViewableQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewableQuery, ViewableQueryVariables>(ViewableDocument, baseOptions);
      }
export function useViewableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewableQuery, ViewableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewableQuery, ViewableQueryVariables>(ViewableDocument, baseOptions);
        }
export type ViewableQueryHookResult = ReturnType<typeof useViewableQuery>;
export type ViewableLazyQueryHookResult = ReturnType<typeof useViewableLazyQuery>;
export type ViewableQueryResult = ApolloReactCommon.QueryResult<ViewableQuery, ViewableQueryVariables>;