import { useEffect, useState } from "react";

export function useDebounce(value, delay = 100) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      if (!debouncedValue) {
        setDebouncedValue(value);
      } else {
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);

        return () => {
          clearTimeout(handler);
        };
      }
    },
    // We do not care if debouncedValue changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, delay]
  );

  return debouncedValue;
}
