import React, { useState } from "react";

const MenuContext = React.createContext<string>(undefined);

const MenuSetterContext = React.createContext<(string) => void>(undefined);

const MenuContextProvider = ({ children }) => {
  const [selectedItem, setSelectedItem] = useState<string>("start");

  return (
    <MenuSetterContext.Provider value={setSelectedItem}>
      <MenuContext.Provider value={selectedItem}>
        {children}
      </MenuContext.Provider>
    </MenuSetterContext.Provider>
  );
};

export {
  MenuContext,
  MenuSetterContext,
  MenuContextProvider
};
