import styled from 'styled-components';

export const H1 = styled.h1`
  color: white;
  font-family: Quattro Sans;
  font-size: 63px;
  line-height: 72px;
  margin: 0;
`

export const H2 = styled.h2`
  margin: 0;
  color: white;
  font-weight: 300;
  font-size: 48px;
`

export const H3 = styled.h3`
  margin: 0;
  color: white;
  font-size: 32px;
  font-family: Quattro Sans;
  line-height: 40px;
`

export const P = styled.p`
  font-size: 28px;
  line-height: 40px;
  color: white;
  margin: 0;
`

export const Ps = styled.p`
  font-size: 28px;
  line-height: 40px;
  color: white;
  margin: 0;
`

export const H3s = styled.h3`
  margin: 0;
  color: white;
  font-weight: 600;
  font-size: 18px;
`
