import { withFocusable } from "@noriginmedia/react-spatial-navigation";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext
} from "react";
import { useParams } from "react-router-dom";
import shaka from "shaka-player";
import youbora from "youboralib";
import youboraShakaAdapater from "youbora-adapter-shaka";
import { usePlayableQuery } from "./graphql";
import PlayerControls from "./PlayerControls";
import PlayerNextEpisode from "./PlayerNextEpisode";
import { PlayerState } from "./PlayerState";
import { useBack } from "./useBack";
import buildYouboraConfig from "./buildYouboraConfig";
import { UserContext } from "./UserContext";

shaka.polyfill.installAll();
const youboraPlugin = new youbora.Plugin({ accountCode: 'tv4' });

const getPlaybackData = async (assetId: string) => {
  const resp = await fetch(
    `https://playback-api.b17g.net/asset/${assetId}?service=cmore.se&device=android&protocol=dash&drm=widevine`,
    {
      headers: {
        "x-jwt": localStorage.getItem("token")
      }
    }
  );
  const data = await resp.json();

  return data;
};

const Play = ({ setFocus }) => {
  const { id } = useParams();
  const { data } = usePlayableQuery({
    variables: { id }
  });
  const [user] = useContext(UserContext);
  const playerWrapperRef = useRef<any>();
  const playerRef = useRef<any>();
  const [playerState, setPlayerState] = useState<string>(PlayerState.LOADING);
  const [subtextActive, setSubtextActive] = useState<boolean>(false);
  const loadingSource = useRef<boolean>(false);

  useBack();

  useEffect(() => {
    if (
      playerWrapperRef.current &&
      id &&
      playerState === PlayerState.LOADING &&
      data &&
      !loadingSource.current
    ) {
      const run = async () => {
        loadingSource.current = true;
        playerRef.current = new shaka.Player(playerWrapperRef.current);
        const player = playerRef.current;
        const playbackData = await getPlaybackData(id);
        const resp = await fetch(`https://playback-api.b17g.net${playbackData.mediaUri}`, {
          headers: {
            "x-jwt": localStorage.getItem("token")
          }
        });
        const mediaData = await resp.json();
        if (mediaData?.playbackItem?.license) {
          const server = mediaData.playbackItem.license.castlabsServer;
          const token = mediaData.playbackItem.license.castlabsToken;

          player
            .getNetworkingEngine()
            .registerRequestFilter((type, request) => {
              if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
                request.headers["x-dt-auth-token"] = token;
              }
            });

          player.configure({
            drm: {
              servers: {
                "com.widevine.alpha": server
              }
            }
          });
        }
        // Listen for error events.
        player.addEventListener("error", (e: any) => console.error(e));
        youboraPlugin.setOptions(buildYouboraConfig({ youboraConfig: playbackData.trackingData.youbora, user }));
        youboraPlugin.setAdapter(new youboraShakaAdapater(player))
        try {
          const startTime: number =
            data.playable?.__typename === "Movie" ||
            data.playable?.__typename === "Episode"
              ? data.playable.progress?.position
              : undefined;
          await player.load(mediaData?.playbackItem?.manifestUrl, startTime);
        } catch (e) {
          console.error(e);
        }
        setPlayerState(PlayerState.PLAYING);
        loadingSource.current = false;
      };
      run();
    }
  }, [id, playerState, data, user]);

  useEffect(() => {
    setFocus();
  }, [setFocus]);

  useEffect(() => {
    if (playerWrapperRef.current) {
      playerWrapperRef.current.addEventListener("pause", () =>
        setPlayerState(PlayerState.PAUSED)
      );
      playerWrapperRef.current.addEventListener("play", () =>
        setPlayerState(PlayerState.PLAYING)
      );
      playerWrapperRef.current.addEventListener("ended", () =>
        setPlayerState(PlayerState.ENDED)
      );
      playerWrapperRef.current.addEventListener("seeked", () => {
        if (playerWrapperRef.current.paused) {
          setPlayerState(PlayerState.PAUSED);
        } else {
          setPlayerState(PlayerState.PLAYING);
        }
      });
    }
  }, []);

  const forward = useCallback(() => {
    setPlayerState(PlayerState.FORWARDING);
    playerWrapperRef.current.currentTime =
      playerWrapperRef.current.currentTime + 30.0;
  }, [playerWrapperRef]);

  const rewind = useCallback(() => {
    setPlayerState(PlayerState.REWINDING);
    playerWrapperRef.current.currentTime =
      playerWrapperRef.current.currentTime - 30.0;
  }, [playerWrapperRef]);

  const play = useCallback(() => {
    setPlayerState(PlayerState.PLAYING);
    playerWrapperRef.current.play();
  }, [playerWrapperRef]);

  const pause = useCallback(() => {
    setPlayerState(PlayerState.PAUSED);
    playerWrapperRef.current.pause();
  }, [playerWrapperRef]);

  const getCurrentTime = useCallback(() => {
    return playerWrapperRef.current.currentTime;
  }, [playerWrapperRef]);

  const resetPlayer = useCallback(async () => {
    await playerRef.current.unload();
    await playerRef.current.destroy();
    setPlayerState(PlayerState.LOADING);
  }, [playerRef, setPlayerState]);

  const toggleSubtext = useCallback(() => {
    const hasSubtext = playerRef.current.getTextLanguages().includes("sv");
    if (hasSubtext && !subtextActive) {
      playerRef.current.selectTextLanguage("sv");
      playerRef.current.setTextTrackVisibility(true);
      setSubtextActive(true);
    }
    if (hasSubtext && subtextActive) {
      playerRef.current.setTextTrackVisibility(false);
      setSubtextActive(false);
    }
  }, [playerRef, subtextActive, setSubtextActive]);

  const duration =
    playerWrapperRef.current && playerWrapperRef.current.duration;
  const showNextEpisodeOverlay =
    data?.playable?.__typename === "Episode" &&
    data.playable.nextEpisode &&
    playerState === PlayerState.ENDED;

  return (
    <Fragment>
      <video
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "black",
          zIndex: -1
        }}
        ref={playerWrapperRef}
        autoPlay
        width="640"
      />
      {data?.playable?.__typename === "Episode" && showNextEpisodeOverlay && (
        <PlayerNextEpisode
          {...data.playable.nextEpisode}
          resetPlayer={resetPlayer}
        />
      )}
      <PlayerControls
        forward={forward}
        rewind={rewind}
        state={playerState}
        pause={pause}
        play={play}
        getCurrentTime={getCurrentTime}
        duration={duration}
        toggleSubtext={toggleSubtext}
        subtextActive={subtextActive}
        title={data?.playable?.title}
        description={data?.playable?.descriptionExtended}
      />
    </Fragment>
  );
};

const FocusablePlay = withFocusable()(Play);

export default FocusablePlay;
