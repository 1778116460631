import React, { Fragment, useContext, useEffect } from "react";
import styled from "styled-components";
import { BackgroundImagSetterContext } from "./BackgroundImageContext";
import { H1, P } from "./typography";

const Information = styled.div`
  max-width: 50%;
  position: absolute;
  left: 152px;
  top: 88px;
`;

const Description = styled(P)`
  margin-top: 12px;
`;

const Header = ({
  image,
  title,
  description,
  year,
  genre
}: {
  image: string;
  title: string;
  description: string;
  year?: number;
  genre?: string;
}) => {
  const setBackGroundImage = useContext(BackgroundImagSetterContext);

  useEffect(() => {
    setBackGroundImage(image);
  }, [image, setBackGroundImage]);

  return (
    <Fragment>
      <Information>
        <H1>{title}</H1>
        <P>{[genre, year].filter(Boolean).join(" \u2022 ")}</P>
        <Description>{description}</Description>
      </Information>
    </Fragment>
  );
};

export default Header;
