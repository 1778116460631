import { withFocusable } from "@noriginmedia/react-spatial-navigation";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from 'styled-components';
import { imageProxyUrl } from "./imageProxy";
import { H3, H3s, P } from "./typography";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.7)
  ),
  url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;
  position: absolute;
  z-index: -1;
`

const Text = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 60%;
  max-width: 800px;
  left: 104px;
  > * {
    &:not(:first-child) {
      margin-top: 16px;
    }
  }
`

const PlayerNextEpisode = ({
  id,
  title,
  image16x9,
  descriptionExtended,
  resetPlayer
}: { id: string, title: string, image16x9: string, descriptionExtended: string, resetPlayer: Function }) => {
  const [countdown, setCountdown] = useState<number>(10);
  const history = useHistory();
  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(countdown => countdown - 1)
    }, 1000);
    return () => clearInterval(interval)
  }, []) // eslint-disable react-hooks/exhaustive-deps

  useEffect(() => {
    if (countdown === 0) {
      resetPlayer();
      history.replace(`/play/${id}`)
    }
  }, [countdown, id, history, resetPlayer])


  return (
    <Wrapper src={imageProxyUrl(image16x9, 950, 640)}>
      <Text>
        <H3s>Nästa avsnitt startar om {countdown} sekunder.</H3s>
        <H3>{title}</H3>
        <P>{descriptionExtended}</P>
      </Text>
    </Wrapper>
  )
}

const FocusablePlayerNextEpisode = withFocusable()(PlayerNextEpisode);

export default FocusablePlayerNextEpisode;
