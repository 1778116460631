import { withFocusable } from "@noriginmedia/react-spatial-navigation";
import React, { useCallback, useContext, useEffect } from "react";
import styled from "styled-components";
import { MenuContext, MenuSetterContext } from "./MenuContext";
import { PageFocusItemContext } from "./PageFocusContext";
import { P } from './typography'
import { useHistory } from "react-router-dom";
import { ReactComponent as StartIcon } from './icons/start.svg';
import { ReactComponent as MoviesIcon } from './icons/movies.svg';
import { ReactComponent as SeriesIcon } from './icons/series.svg';
import { ReactComponent as KidsIcon } from './icons/kids.svg';
import { ReactComponent as Tv4Icon } from './icons/tv4.svg';
import { ReactComponent as SportsIcon } from './icons/sports.svg';
import { ReactComponent as ChannelsIcon } from './icons/channels.svg';
import { UserContext } from "./UserContext";
import { User } from "./graphql";

const MenuWrapper = styled.div`
  background: linear-gradient(180deg, #0D1A2C 0%, #06111F 100%);
  height: 100%;
  padding-top: 10%;
  position: absolute;
  min-width: ${props => (props.focused ? "400px" : "80px")};
  transition: min-width 0.2s ease;
  z-index: 1;
  > * p {
    display: ${props => (props.focused ? "block" : "none")};
  }
  div:first-child {
    width: calc(100% - 20px);
    position: absolute;
    top: 0;
  }
`;

const Item = styled.div`
  box-shadow: ${props => props.selected ? "8px 0px 0 #FF3334 inset;" : "none"};
  background-color: ${({ focused, selected, expanded }) => {
    if (selected && !expanded) return "#36404D"
    if (focused) return "#36404D"
    if (selected) return "#172332"
    return "transparent"
  }};
  transition: background-color 0.2s ease;
  padding-left: 20px;
  svg  > * {
    fill-opacity: ${props => (props.selected && !props.expanded) ? 1 : props.focused ? 1 : 0.25};
    stroke-opacity: ${props => (props.selected && !props.expanded)? 1 : props.focused ? 1 : 0.25};
  }
`;

const Block = styled.span`
  height: 80px;
  display: flex;
  align-items: center;
  p {
    margin-left: 28px;
  }
`

const ProfileImg = styled.img`
  width: 40px;
`

const MenuItem = withFocusable()(
  ({ focused, title, icon, value, selectedMenuItem, expanded }) => (
    <Item focused={focused} expanded={expanded} selected={value === selectedMenuItem}>
      <Block>{icon} <P>{title}</P></Block>
    </Item>
  )
);

const Menu = ({ hasFocusedChild, setFocus }) => {
  const selectedMenuItem = useContext(MenuContext);
  const setSelectedMenuItem = useContext(MenuSetterContext);
  const focusItem = useContext(PageFocusItemContext);
  const user = useContext<User>(UserContext);
  const history = useHistory();

  useEffect(() => {
    setSelectedMenuItem(history.location.pathname);
    setFocus(history.location.pathname);
  }, [history.location.pathname, setSelectedMenuItem, setFocus]);

  const onEnterPress = useCallback(
    ({ value }) => {
      history.push(value);
    },
    [history]
  );

  const onArrowPress = useCallback(
    direction => {
      if (direction === "right" || direction === "left") {
        setFocus(`${focusItem.row}-${focusItem.column}`);
        return false;
      }
    },
    [setFocus, focusItem]
  );

  const getMenuItems = useCallback(() => ([
    { title: user[0]?.currentProfile?.alias, value: "/profile", icon: <ProfileImg src={user[0]?.currentProfile?.avatar?.head} /> },
    { title: "Hem", value: "/", icon: <StartIcon /> },
    { title: "Filmer", value: "/pages/movies", icon: <MoviesIcon /> },
    { title: "Serier", value: "/pages/series", icon: <SeriesIcon /> },
    { title: "Barn & familj", value: "/pages/kids", icon: <KidsIcon /> },
    { title: "TV-kanaler", value: "/channels", icon: <ChannelsIcon /> },
    { title: "TV4 Program", value: "/pages/programs", icon: <Tv4Icon /> },
    { title: "Sport", value: "/pages/sports", icon: <SportsIcon /> },
  ]), [user])

  return (
    <MenuWrapper focused={hasFocusedChild}>
      {getMenuItems().map(({ title, value, icon }) => (
        <MenuItem
          key={value}
          onEnterPress={onEnterPress}
          onArrowPress={onArrowPress}
          title={title}
          icon={icon}
          value={value}
          focusKey={value}
          selectedMenuItem={selectedMenuItem}
          expanded={hasFocusedChild}
        />
      ))}
    </MenuWrapper>
  );
};

const FocusableMenu = withFocusable({ trackChildren: true })(Menu);

export default FocusableMenu;
