import { withFocusable } from "@noriginmedia/react-spatial-navigation";
import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useCallback
} from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { BackgroundImagSetterContext } from "./BackgroundImageContext";
import {
  EpisodeFragment,
  MovieFragment,
  SportEventFragment,
  useSeasonQuery,
  useViewableQuery,
  ClipFragment
} from "./graphql";
import Header from "./Header";
import PlayIcon from "./icons/play.svg";
import { imageProxyUrl } from "./imageProxy";
import { PageFocusItemContext } from "./PageFocusContext";
import { LandscapeListModule, PosterListModule } from "./PosterListModule";
import { H3s, P } from "./typography";
import { useBack } from "./useBack";

const StyledSeasonButton = withFocusable()(styled.button`
  background-color: ${props =>
    props.focused ? "#ED4040;" : props.selected ? "#6E7680" : "#313C4A"};
  border: none;
  height: 58px;
  border-radius: 8px;
  color: white;
  margin-right: 16px;
  transition: background-color 0.2s ease;
`);

const SeasonButton = withFocusable()(({ children, focused, selected }) => (
  <StyledSeasonButton selected={selected} focused={focused}>
    {children}
  </StyledSeasonButton>
));

const EpisodeListWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 60%;
  left: 152px;
  overflow: hidden;
  max-width: 100%;
  ::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
`;

const EpisodeList = styled.div`
  display: flex;
  flex-wrap: wrap;
  transform: ${props => `translateY(-${props.y}px)`};
  transition: transform 0.2s ease;
`;

const SeasonButtonsStyle = styled.div`
  margin: 36px 0 0 152px;
`;

const SeasonButtons = withFocusable()(({ children }) => (
  <SeasonButtonsStyle>{children}</SeasonButtonsStyle>
));

const PlayButtonStyle = styled.button`
  background-color: ${props => (props.focused ? "#ED4040" : "#313C4A")};
  padding: 8px 32px;
  margin-left: 152px;
  border-radius: 8px;
  height: 58px;
  margin-top: 32px;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease;
`;

const PlayButton = withFocusable()(({ children, focused }) => (
  <PlayButtonStyle focused={focused}>{children}</PlayButtonStyle>
));

const EpisodeListItemStyle = styled.div`
  border: ${props =>
    props.focused ? "1px solid white" : "1px solid transparent"};
  width: 22%;
  margin: 8px;
  position: relative;
  transition: border 0.2s ease;
`;

const EpisodeImage = styled.img`
  width: 100%;
`;

const PlayIconWrapper = styled.img`
  opacity: ${props => (props.show ? 1 : 0)};
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: opacity 0.2s ease;
`;

const ProgressWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 5px;
`;

const TextWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
`;
const Progress = styled.div`
  height: 100%;
  background-color: red;
  width: ${props => props.width || 0}%;
`;
const SimilarItems = styled.div`
  overflow: hidden;
  > div {
    transition: transform 0.2s ease;
  }
  height: 50%;
  max-width: 100%;
  margin: 0 56px;
  top: 50%;
  ::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
`;

const EpisodeListItem = withFocusable()(
  ({ episode, focused }: { episode: any; focused: boolean }) => {
    return (
      <EpisodeListItemStyle focused={focused}>
        <EpisodeImage alt="" src={imageProxyUrl(episode.image16x9, 320, 180)} />
        <TextWrapper>
          <H3s>{episode.title}</H3s>
          <P>{episode.readableDuration}</P>
        </TextWrapper>
        <PlayIconWrapper show={focused} src={PlayIcon} />
        <ProgressWrapper>
          <Progress width={episode.progress?.percent} />
        </ProgressWrapper>
      </EpisodeListItemStyle>
    );
  }
);

const SeasonStuff = withFocusable()(
  ({
    seriesId,
    initialSeasonNumber
  }: {
    seriesId: string;
    initialSeasonNumber: number;
    setFocus: Function;
  }) => {
    const [seasonNumber, setSeasonNumber] = useState(initialSeasonNumber);
    const setBackGroundImage = useContext(BackgroundImagSetterContext);
    const { data } = useSeasonQuery({
      variables: { seasonNumber, seriesId }
    });
    const [yPosition, setYPosition] = useState<number>(8);
    const history = useHistory();
    return (
      <Fragment>
        <SeasonButtons preferredChildFocusKey={`season-${seasonNumber}`}>
          {(data?.series?.seriesSeasons || []).map(season => (
            <SeasonButton
              focusKey={`season-${season.seasonNumber}`}
              selected={season.seasonNumber === seasonNumber}
              key={season.seasonNumber}
              onBecameFocused={() => setSeasonNumber(season.seasonNumber)}
            >
              <P>{season.title}</P>
            </SeasonButton>
          ))}
        </SeasonButtons>
        <EpisodeListWrapper>
          <EpisodeList y={yPosition}>
            {(data?.series?.seriesSeason?.episodes || []).map(episode => (
              <EpisodeListItem
                onEnterPress={() => history.push(`/play/${episode.id}`)}
                key={episode.id}
                episode={episode}
                onBecameFocused={({ y }) => {
                  setYPosition(y);
                  setBackGroundImage(episode.image16x9);
                }}
              />
            ))}
          </EpisodeList>
        </EpisodeListWrapper>
      </Fragment>
    );
  }
);

const Episode = withFocusable()(
  ({ episode, setFocus }: { episode: EpisodeFragment; setFocus: Function }) => {
    const history = useHistory();

    useEffect(() => {
      setFocus("play-button");
    }, [setFocus]);

    const seasonNumber = episode.season.seasonNumber;
    const seriesId = episode.series.id;
    const playableId = episode.id;
    const text = `${episode.season.title} - ${episode.title}`;

    return (
      <div>
        <Header
          image={episode.series.image16x9}
          description={episode.series.description}
          title={episode.series.title}
          year={episode.series.year}
          genre={episode.series.genre}
        />
        <div style={{ paddingTop: "400px" }}>
          <PlayButton
            focusKey="play-button"
            onEnterPress={() => history.push(`/play/${playableId}`)}
          >
            <img alt="play" src={PlayIcon} />
            <P>{text}</P>
          </PlayButton>

          {seasonNumber !== undefined && (
            <SeasonStuff
              seriesId={seriesId}
              initialSeasonNumber={seasonNumber}
            />
          )}
        </div>
      </div>
    );
  }
);

const Movie = withFocusable()(
  ({ movie, setFocus }: { movie: MovieFragment; setFocus: Function }) => {
    const history = useHistory();

    const focusItem = useContext(PageFocusItemContext);

    useEffect(() => {
      setFocus("play-button");
    }, [setFocus]);

    const onEnterPress = useCallback(
      ({ itemId }) => {
        history.push(`/viewable/${itemId}`);
      },
      [history]
    );

    return (
      <div>
        <Header
          image={movie.image16x9}
          description={movie.description}
          title={movie.title}
          year={movie.year}
          genre={movie.genre}
        />
        <div style={{ paddingTop: "400px" }}>
          <PlayButton
            focusKey="play-button"
            onEnterPress={() => history.push(`/play/${movie.id}`)}
          >
            <img alt="play" src={PlayIcon} />
            <P>{movie.title}</P>
          </PlayButton>
          <SimilarItems>
            <PosterListModule
              rowNumber={0}
              list={movie.similars}
              selectedColumn={focusItem?.row === 0 ? focusItem.column : -1}
              onEnterPress={onEnterPress}
            />
          </SimilarItems>
        </div>
      </div>
    );
  }
);

const SportEvent = withFocusable()(
  ({
    sportEvent,
    setFocus
  }: {
    sportEvent: SportEventFragment;
    setFocus: Function;
  }) => {
    const history = useHistory();

    const focusItem = useContext(PageFocusItemContext);

    useEffect(() => {
      setFocus("play-button");
    }, [setFocus]);

    const onEnterPress = useCallback(
      ({ itemId }) => {
        history.push(`/viewable/${itemId}`);
      },
      [history]
    );

    return (
      <div>
        <Header
          image={sportEvent.image16x9}
          description={sportEvent.description}
          title={sportEvent.title}
          year={sportEvent.year}
          genre={sportEvent.genre}
        />
        <div style={{ paddingTop: "400px" }}>
          <PlayButton
            focusKey="play-button"
            onEnterPress={() => history.push(`/play/${sportEvent.id}`)}
          >
            <img alt="play" src={PlayIcon} />
            <P>{sportEvent.title}</P>
          </PlayButton>
          <SimilarItems>
            <LandscapeListModule
              rowNumber={0}
              list={sportEvent.similars}
              selectedColumn={focusItem?.row === 0 ? focusItem.column : -1}
              onEnterPress={onEnterPress}
            />
          </SimilarItems>
        </div>
      </div>
    );
  }
);

const Clip = withFocusable()(
  ({ clip, setFocus }: { clip: ClipFragment; setFocus: Function }) => {
    const history = useHistory();

    useEffect(() => {
      setFocus("play-button");
    }, [setFocus]);

    return (
      <div>
        <Header
          image={clip.image16x9}
          description={clip.description}
          title={clip.title}
          year={clip.year}
          genre={clip.genre}
        />
        <div style={{ paddingTop: "400px" }}>
          <PlayButton
            focusKey="play-button"
            onEnterPress={() => history.push(`/play/${clip.id}`)}
          >
            <img alt="play" src={PlayIcon} />
            <P>{clip.title}</P>
          </PlayButton>
        </div>
      </div>
    );
  }
);

const Viewable = () => {
  useBack();
  const { id } = useParams();
  const { data } = useViewableQuery({ variables: { id } });

  const viewable = data?.viewable;

  switch (viewable?.__typename) {
    case "Episode":
      return <Episode episode={viewable} />;
    case "Series":
      return <Episode episode={viewable.suggestedEpisode} />;
    case "Movie":
      return <Movie movie={viewable} />;
    case "SportEvent":
      return <SportEvent sportEvent={viewable} />;
    case "Clip":
      return <Clip clip={viewable} />;
    default:
      return null;
  }
};

export default Viewable;
