import { withFocusable } from "@noriginmedia/react-spatial-navigation";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Forward } from './icons/forward.svg';
import { ReactComponent as Pause } from './icons/pause.svg';
import { ReactComponent as Play } from './icons/play.svg';
import { ReactComponent as Rewind } from './icons/rewind.svg';
import { ReactComponent as Subtext } from './icons/subtext.svg';
import { PlayerState } from './PlayerState';
import { H3, P } from "./typography";

const ControlsStyle = styled.div`
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7));
  transition: opacity 0.2s ease;
  display: flex;
  position: absolute;
  flex-direction: column;
  opacity: ${props => props.show ? 1 : 0}
`

const TextWrapper = styled.div`
  position: absolute;
  top: 30%;
  left: 104px;
  max-width: 800px;
  > * {
    &:not(:first-child) {
      margin-top: 16px;
    }
  }
`

const ProgressWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 80%;
  width: 100%;
`

const Icon = styled(({ component, ...props }) => React.cloneElement(component, props))`
  transform: ${props => props.small ? 'scale(1);' : 'scale(2.5);'};
  path {
    transition: stroke 0.2s ease;
    stroke: ${props => props.selected ? '#FC363C' : 'white'};
  }
`

const SubtextIcon = styled(Subtext)`
  transform: scale(1);
  margin-bottom: 16px;
  path {
    transition: stroke 0.2s ease;
    stroke: ${props => props.active ? '#6E7680' : 'white'};
}
`

const Controlers = styled.div`
  min-width: 175px;
  display: flex;
  justify-content: space-between;
  margin-left: 104px;
  align-items: center;
  flex-wrap: wrap;
  svg {
    flex: 1 0 32%;
  }
  svg:first-child {
    flex: 0 1 100%;
  }
`

const Duration = styled.div`
  width: 100%;
  position: relative;
  margin: 62px 32px 0 32px;
  display: flex;
  align-items: center;
`

const ProgressBar = styled.div`
  border-radius: 6px;
  margin-right: 16px;
  height: 4px;
  width: 100%;
  position: relative;
`;

const Bar = styled.span`
  display: block;
  background: #313C4A;
`;

const Progress = styled.span`
  animation: width 2s ease;
  display: block;
  background: #FC363C;
  height: 4px
  width: ${props => props.progress || 0}%;
`;

const ElapsedWrapper = styled.div`
  width: 70px;
  position: absolute;
  top: -62px;
  left: calc(${props => props.left || 0}% - 30px);
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Dot = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: white;
`

const Text = styled.p`
  color: white;
  font-size: 18px;
  font-weight: 100;
  letter-spacing: 1px;
`

const EndText = styled(Text)`
  width: 100px;
  min-width: 100px;
  max-width: 100px;
`

const secondsToReadable = (sec) => {
  const seconds = Math.floor((sec) % 60);
  const minutes = Math.floor((sec / 60) % 60);
  const hour = Math.floor((sec / (60 * 60)) % 60);
  const pSec = seconds < 10 ? `0${seconds}` : seconds;
  const pMin = minutes < 10 ? `0${minutes}` : minutes;
  if (hour) return `${hour}:${minutes}:${pSec}`;
  return `${pMin}:${pSec}`
}

const Controls = ({ show, state, position, duration, subtextActive, title, description }) => {
  const progressProcent = Math.round((position / duration) * 100);
  const timeToEnd = secondsToReadable(Math.round(duration - position));
  const elapsed = secondsToReadable(Math.round(position));

  return (
    <ControlsStyle show={show}>
      <TextWrapper>
        <H3>{title}</H3>
        <P>{description}</P>
      </TextWrapper>
      <ProgressWrapper>
        <Controlers>
          <SubtextIcon active={subtextActive ? 1 : 0} />
          <Icon small="1" selected={state === PlayerState.REWINDING} component={<Rewind />} />
          {state === PlayerState.PAUSED ? <Icon selected={state === PlayerState.PAUSED} component={<Play />} /> : <Icon selected={state === PlayerState.PLAYING} component={<Pause />} />}
          <Icon small="1" selected={state === PlayerState.FORWARDING} component={<Forward />} />
        </Controlers>
        <Duration>
          <ProgressBar>
            <ElapsedWrapper left={progressProcent}>
              <Text>{elapsed}</Text>
              <Dot />
            </ElapsedWrapper>
            <Bar>
              <Progress progress={progressProcent} />
            </Bar>
          </ProgressBar>
          <EndText>{timeToEnd}</EndText>
        </Duration>
      </ProgressWrapper>
    </ControlsStyle>
  );
};

const FocusableControls = withFocusable({ focusable: false })(Controls);


const ControlsWrapper = ({
  forward,
  rewind,
  state,
  pause,
  play,
  duration,
  getCurrentTime,
  toggleSubtext,
  subtextActive,
  title,
  description
}) => {
  const [showPlayerControls, setShowPlayerControls] = useState<boolean>(false);
  const [position, setPosition] = useState(0);
  const controlsTimer = useRef<any>();
  const intervalRef = useRef<any>();

  useEffect(() => {
    if (showPlayerControls) {
      const interval = setInterval(() => {
        setPosition(getCurrentTime())
      }, 500);
      intervalRef.current = interval;
    } else {
      clearInterval(intervalRef.current)
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [showPlayerControls, getCurrentTime])

  useEffect(() => {
    if (showPlayerControls && state === PlayerState.PAUSED) {
      clearTimeout(controlsTimer.current)
      return;
    };
    if (showPlayerControls) {
      if (controlsTimer.current) {
        clearTimeout(controlsTimer.current)
      }
      controlsTimer.current = setTimeout(() => {
        setShowPlayerControls(false)
      }, 5000);

    }
    return () => {
      if (controlsTimer.current) {
        clearTimeout(controlsTimer.current)
      }
    }
  }, [showPlayerControls, setShowPlayerControls, state])

  const onArrowPress = useCallback(direction => {
    setShowPlayerControls(true)
    if (direction === "right") forward()
    if (direction === "left") rewind()
    if (direction === "up") toggleSubtext()
  }, [forward, rewind, toggleSubtext])

  const onEnterPress = useCallback(() => {
    setShowPlayerControls(true)
    if (state === PlayerState.PLAYING) {
      return pause();
    }
    if (state === PlayerState.PAUSED) {
      return play();
    }
  }, [setShowPlayerControls, pause, play, state]);

  return (
    <FocusableControls
      state={state}
      show={showPlayerControls}
      duration={duration}
      position={position}
      subtextActive={subtextActive}
      onArrowPress={onArrowPress}
      onEnterPress={onEnterPress}
      title={title}
      description={description}
    />
  );
};

const FocusablePlayerControls = withFocusable({ focusable: false })(ControlsWrapper);

export default FocusablePlayerControls;
