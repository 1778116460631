import { useHistory } from "react-router-dom";
import { useEffect } from "react";

export const useBack = () => {
  const history = useHistory();

  useEffect(() => {
    const downHandler = ({ keyCode, metaKey }: KeyboardEvent) => {
      if (keyCode === 10009) {
        history.goBack();
      }

      if (keyCode === 37 && metaKey) {
        history.goBack();
      }
    };

    window.addEventListener("keydown", downHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  }, [history]);

  return;
};
